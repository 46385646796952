import { logger } from "./logger";

// CDN URL transformation utility
const CDN_ENABLED = process.env.REACT_APP_CDN_ENABLED === "true";
const CDN_DOMAIN = process.env.REACT_APP_CDN_DOMAIN || "media.tourde4.com";
const BUCKET_NAME = "tourde4-c7a16.firebasestorage.app";

// Debug log environment variables
logger.info("CDN Settings:", {
  CDN_ENABLED,
  CDN_DOMAIN,
  BUCKET_NAME,
  raw_enabled: process.env.REACT_APP_CDN_ENABLED,
});

/**
 * Transforms a Firebase Storage URL or local path to use our CDN domain if enabled
 * @param {string} url - The original URL or path
 * @returns {string} - The transformed URL or original if transformation not needed
 */
export const getCdnUrl = (url) => {
  if (!url || !CDN_ENABLED) return url;

  try {
    // Handle Firebase Storage URLs
    if (url.includes("firebasestorage.googleapis.com")) {
      // Extract the path portion from the Firebase URL
      const match = url.match(/o\/(.+?)\?/);
      if (!match) return url;

      // Decode the path and remove any query parameters
      const path = decodeURIComponent(match[1]);

      // If the path starts with 'cdn/', remove it
      const cleanPath = path.startsWith("cdn/") ? path.substring(4) : path;

      // Remove any timestamp prefixes (e.g., 1738194744833-)
      const finalPath = cleanPath.replace(/^\d{13}-/, "");

      return `https://${CDN_DOMAIN}/${finalPath}`;
    }

    // Handle local paths (starting with /)
    if (url.startsWith("/")) {
      // Remove the leading slash
      const path = url.substring(1);
      return `https://${CDN_DOMAIN}/${path}`;
    }

    // Return all other URLs unchanged
    return url;
  } catch (error) {
    logger.error("Error transforming URL:", error);
    return url;
  }
};

/**
 * Transforms a path to a CDN URL
 * @param {string} path - The storage path (e.g., 'images/photo.jpg')
 * @returns {string} - The CDN URL
 */
export const pathToCdnUrl = (path) => {
  if (!path || !CDN_ENABLED) return path;
  // If the path starts with 'cdn/', remove it
  const cleanPath = path.startsWith("cdn/") ? path.substring(4) : path;
  return `https://${CDN_DOMAIN}/${cleanPath}`;
};
