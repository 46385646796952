import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useContent } from "../hooks/useContent";
import { Helmet } from "react-helmet-async";
import { useSeoSettings } from "../hooks/useSeoSettings";
import {
  ChevronRight,
  ExternalLink,
  ChevronLeft,
  ArrowDown,
} from "lucide-react";
import { Link } from "react-router-dom";
import { getCdnUrl } from "../utils/cdnUtils";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

// Helper function to ensure external links are properly formatted
const formatExternalLink = (link) => {
  if (!link) return "";
  if (link.startsWith("http://") || link.startsWith("https://")) {
    return link;
  }
  return `https://${link}`;
};

const formatImageUrl = (imageSrc) => {
  if (!imageSrc || typeof imageSrc !== "string") return "";
  if (imageSrc.startsWith("http://") || imageSrc.startsWith("https://")) {
    return imageSrc;
  }
  // If the URL already contains encoded characters, it's likely a full Firebase URL
  if (imageSrc.includes("%2F")) {
    return imageSrc;
  }
  return `/images/${imageSrc}`;
};

const ITEMS_PER_PAGE = 3;

const NewsAndMedia = () => {
  const { content, contentLoading } = useContent("newsAndMedia");
  const { seoSettings } = useSeoSettings();
  const [newsPage, setNewsPage] = useState(0);
  const [mediaPage, setMediaPage] = useState(0);

  // Sort articles by date (most recent first)
  const sortedNewsItems =
    content?.newsItems?.sort((a, b) => new Date(b.date) - new Date(a.date)) ||
    [];

  const sortedMediaItems =
    content?.mediaItems?.sort((a, b) => new Date(b.date) - new Date(a.date)) ||
    [];

  const newsPages = Math.ceil((sortedNewsItems?.length || 0) / ITEMS_PER_PAGE);
  const mediaPages = Math.ceil(
    (sortedMediaItems?.length || 0) / ITEMS_PER_PAGE
  );

  const currentNewsItems = sortedNewsItems.slice(
    newsPage * ITEMS_PER_PAGE,
    (newsPage + 1) * ITEMS_PER_PAGE
  );

  const currentMediaItems = sortedMediaItems.slice(
    mediaPage * ITEMS_PER_PAGE,
    (mediaPage + 1) * ITEMS_PER_PAGE
  );

  if (contentLoading) {
    return (
      <div className="min-h-screen bg-slate-800 flex items-center justify-center">
        <div className="text-white">Loading...</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        {/* Preload featured article image if exists */}
        {content?.featured?.image && (
          <link
            rel="preload"
            as="image"
            href={getCdnUrl(content.featured.image)}
            fetchpriority="high"
          />
        )}
        <title>
          {seoSettings?.newsAndMedia?.title || "News & Media - Tour de 4"}
        </title>
        <meta
          name="description"
          content={
            seoSettings?.newsAndMedia?.description ||
            "Latest news and media updates from Tour de 4"
          }
        />
      </Helmet>

      <div className="min-h-screen bg-gradient-to-b from-slate-800 via-slate-900 to-slate-800">
        {/* Hero Section */}
        <motion.div
          className="min-h-[50vh] flex items-center justify-center relative overflow-hidden"
          style={{
            background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
          }}
        >
          {/* Decorative Background Element */}
          <motion.div
            className="absolute top-20 left-20 w-96 h-96 rounded-full opacity-10"
            style={{
              background: colors.secondary,
              filter: "blur(60px)",
            }}
            animate={{
              scale: [1, 1.2, 1],
              rotate: [0, 90, 0],
            }}
            transition={{
              duration: 20,
              ease: "linear",
              repeat: Infinity,
            }}
          />

          <div className="max-w-7xl mx-auto px-8 text-center relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.4, delay: 0.2 }}
              className="space-y-8"
            >
              <h1 className="text-5xl md:text-6xl font-bold text-white mb-8">
                {content?.hero?.title || "News & Media"}
              </h1>
              <p className="text-2xl text-white/70 max-w-3xl mx-auto">
                {content?.hero?.subtitle ||
                  "Stay updated with our latest news, events, and media coverage."}
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.8 }}
              className="mt-12"
            >
              <ArrowDown className="w-8 h-8 text-white/50 mx-auto animate-bounce" />
            </motion.div>
          </div>
        </motion.div>

        {/* News Grid with white background */}
        <section className="py-20 px-4 sm:px-6 lg:px-8 bg-white">
          <div className="max-w-7xl mx-auto">
            <div className="flex justify-between items-center mb-12">
              <h2 className="text-3xl font-bold text-slate-800">Latest News</h2>
              {newsPages > 1 && (
                <div className="flex items-center gap-4">
                  <button
                    onClick={() => setNewsPage((prev) => Math.max(0, prev - 1))}
                    disabled={newsPage === 0}
                    className={`p-2 rounded-full ${
                      newsPage === 0
                        ? "text-slate-400"
                        : "text-slate-600 hover:bg-slate-100"
                    }`}
                  >
                    <ChevronLeft className="w-6 h-6" />
                  </button>
                  <span className="text-slate-600">
                    {newsPage + 1} / {newsPages}
                  </span>
                  <button
                    onClick={() =>
                      setNewsPage((prev) => Math.min(newsPages - 1, prev + 1))
                    }
                    disabled={newsPage === newsPages - 1}
                    className={`p-2 rounded-full ${
                      newsPage === newsPages - 1
                        ? "text-slate-400"
                        : "text-slate-600 hover:bg-slate-100"
                    }`}
                  >
                    <ChevronRight className="w-6 h-6" />
                  </button>
                </div>
              )}
            </div>
            <AnimatePresence mode="wait">
              <motion.div
                key={newsPage}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
              >
                {currentNewsItems.length > 0 ? (
                  currentNewsItems.map((item, index) => (
                    <motion.article
                      key={item.id}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="relative group"
                    >
                      {/* Gradient background */}
                      <div className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-accent/20 rounded-xl transform -rotate-1 group-hover:rotate-0 transition-transform"></div>
                      {/* Content box */}
                      <div className="relative bg-white shadow-lg rounded-xl overflow-hidden hover:shadow-xl transition-all">
                        {item.imageSrc && (
                          <img
                            src={getCdnUrl(item.imageSrc)}
                            alt={item.title}
                            className="w-full h-48 object-cover"
                          />
                        )}
                        <div className="p-6">
                          <p className="text-sm text-secondary font-medium mb-2">
                            {new Date(item.date).toLocaleDateString("en-GB", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            })}
                          </p>
                          <h3 className="text-xl font-semibold text-slate-800 mb-2">
                            {item.title}
                          </h3>
                          <div
                            className="text-slate-600 mb-4 prose prose-sm"
                            dangerouslySetInnerHTML={{ __html: item.excerpt }}
                          />
                          <div className="flex items-center space-x-4">
                            <Link
                              to={`/dev/news/${item.id}`}
                              className="inline-flex items-center text-secondary hover:text-accent transition-colors font-medium"
                            >
                              Read More
                              <ChevronRight className="w-4 h-4 ml-1" />
                            </Link>
                            {item.link && (
                              <a
                                href={formatExternalLink(item.link)}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="inline-flex items-center text-slate-500 hover:text-slate-700 transition-colors"
                              >
                                External Link
                                <ChevronRight className="w-4 h-4 ml-1" />
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </motion.article>
                  ))
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="col-span-full flex flex-col items-center justify-center py-16 text-center"
                  >
                    <svg
                      className="w-16 h-16 text-slate-300 mb-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                      />
                    </svg>
                    <h3 className="text-xl font-semibold text-slate-800 mb-2">
                      No News Articles Yet
                    </h3>
                    <p className="text-slate-600 max-w-md">
                      Check back soon for the latest updates and news about Tour
                      de 4's journey and impact.
                    </p>
                  </motion.div>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </section>

        {/* Media Section with gradient backgrounds */}
        <section className="py-20 px-4 sm:px-6 lg:px-8 bg-white/5">
          <div className="max-w-7xl mx-auto">
            <div className="flex justify-between items-center mb-12">
              <h2 className="text-3xl font-bold text-white">Media Coverage</h2>
              {mediaPages > 1 && (
                <div className="flex items-center gap-4">
                  <button
                    onClick={() =>
                      setMediaPage((prev) => Math.max(0, prev - 1))
                    }
                    disabled={mediaPage === 0}
                    className={`p-2 rounded-full ${
                      mediaPage === 0
                        ? "text-white/40"
                        : "text-white/70 hover:bg-white/10"
                    }`}
                  >
                    <ChevronLeft className="w-6 h-6" />
                  </button>
                  <span className="text-white/70">
                    {mediaPage + 1} / {mediaPages}
                  </span>
                  <button
                    onClick={() =>
                      setMediaPage((prev) => Math.min(mediaPages - 1, prev + 1))
                    }
                    disabled={mediaPage === mediaPages - 1}
                    className={`p-2 rounded-full ${
                      mediaPage === mediaPages - 1
                        ? "text-white/40"
                        : "text-white/70 hover:bg-white/10"
                    }`}
                  >
                    <ChevronRight className="w-6 h-6" />
                  </button>
                </div>
              )}
            </div>
            <AnimatePresence mode="wait">
              <motion.div
                key={mediaPage}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
                className="grid grid-cols-1 md:grid-cols-2 gap-8"
              >
                {currentMediaItems.length > 0 ? (
                  currentMediaItems.map((item, index) => (
                    <motion.div
                      key={item.id}
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="relative group"
                    >
                      {/* Gradient background */}
                      <div className="absolute inset-0 bg-gradient-to-r from-accent/20 to-secondary/20 rounded-xl transform rotate-1 group-hover:rotate-0 transition-transform"></div>
                      {/* Content box */}
                      <div className="relative bg-white shadow-lg rounded-xl overflow-hidden p-6 hover:shadow-xl transition-all">
                        <div className="flex items-start space-x-4">
                          {item.sourceLogo && (
                            <img
                              src={getCdnUrl(item.sourceLogo)}
                              alt={item.source}
                              className="w-12 h-12 object-contain"
                            />
                          )}
                          <div className="flex-1">
                            <h3 className="text-xl font-semibold text-slate-800 mb-2">
                              {item.title}
                            </h3>
                            <div
                              className="text-slate-600 mb-4 prose prose-sm"
                              dangerouslySetInnerHTML={{ __html: item.excerpt }}
                            />
                            <div className="flex items-center space-x-4">
                              <span className="text-sm text-secondary font-medium">
                                {item.source}
                              </span>
                              <span className="text-sm text-slate-500">
                                {new Date(item.date).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                  }
                                )}
                              </span>
                              <div className="flex items-center space-x-4">
                                <Link
                                  to={`/dev/media/${item.id}`}
                                  className="text-sm text-secondary hover:text-accent transition-colors font-medium"
                                >
                                  Read More
                                  <ChevronRight className="w-4 h-4 ml-1 inline" />
                                </Link>
                                {item.link && (
                                  <a
                                    href={formatExternalLink(item.link)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-sm text-slate-500 hover:text-slate-700 transition-colors"
                                  >
                                    External Link
                                    <ExternalLink className="w-4 h-4 ml-1 inline" />
                                  </a>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  ))
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="col-span-full flex flex-col items-center justify-center py-16 text-center"
                  >
                    <svg
                      className="w-16 h-16 text-white/30 mb-4"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
                      />
                    </svg>
                    <h3 className="text-xl font-semibold text-white mb-2">
                      No Media Coverage Yet
                    </h3>
                    <p className="text-white/70 max-w-md">
                      Stay tuned for upcoming media features and coverage of
                      Tour de 4's initiatives and achievements.
                    </p>
                  </motion.div>
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        </section>
      </div>
    </>
  );
};

export default NewsAndMedia;
