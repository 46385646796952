// firebase/routeProtection.js
import { db } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";

const ROUTE_SETTINGS_DOC = "settings/routeProtection";

// Default route configurations
const DEFAULT_ROUTE_SETTINGS = {
  siteMode: "development", // 'development' or 'live'
  routes: {
    home: { published: true },
    about: { published: true },
    support: { published: true },
    resources: { published: true },
    getInvolved: { published: true },
    contact: { published: true },
    news: { published: true },
  },
  mainLandingPage: {
    enabled: true, // Controls if the holding page is shown
  },
  adminPortal: {
    enabled: true, // Always true, kept for backwards compatibility
  },
};

// Cache for route protection settings
let settingsCache = null;
let lastFetchTime = 0;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

export const getRouteProtectionSettings = async () => {
  // Return cached settings if they exist and aren't expired
  const now = Date.now();
  if (settingsCache && now - lastFetchTime < CACHE_DURATION) {
    return settingsCache;
  }

  const docRef = doc(db, ROUTE_SETTINGS_DOC);
  const docSnap = await getDoc(docRef);

  // Update cache
  settingsCache = docSnap.exists()
    ? { ...DEFAULT_ROUTE_SETTINGS, ...docSnap.data() }
    : DEFAULT_ROUTE_SETTINGS;
  lastFetchTime = now;

  return settingsCache;
};

export const updateRouteProtection = async (settings) => {
  const docRef = doc(db, ROUTE_SETTINGS_DOC);
  await setDoc(docRef, {
    ...settings,
    adminPortal: { enabled: true }, // ensure admin portal is always protected
  });

  // Invalidate cache when settings are updated
  settingsCache = null;
  lastFetchTime = 0;
};

// Helper function to check if a route is accessible
export const isRouteAccessible = (settings, routeName) => {
  // Admin portal is always accessible (but protected)
  if (routeName === "adminPortal") return true;

  // Main landing page is only accessible in development mode
  if (routeName === "mainLandingPage") {
    return settings?.mainLandingPage?.enabled || false;
  }

  // Check if the route exists and is published
  return settings?.routes?.[routeName]?.published || false;
};
