import React, { useState, useEffect } from "react";
import { motion, useScroll, useTransform, useSpring } from "framer-motion";
import { Facebook, Instagram } from "lucide-react";
import SponsorsSection from "../Components/SponsorsSection";
import RegisterInterestModal from "../Components/RegisterInterestModal";
import { useContent } from "../hooks/useContent";
import { useNavigate } from "react-router-dom";
import { useSiteMode } from "../hooks/useSiteMode";
import { useSeoSettings } from "../hooks/useSeoSettings";
import { Helmet } from "react-helmet-async";
import { getCdnUrl } from "../utils/cdnUtils";
import HomePageSkeleton from "../Components/HomePageSkeleton";

const XIcon = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    className={className}
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
  </svg>
);

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

const DecorativeSection = ({ children, className = "" }) => (
  <div className="relative">
    <div className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-accent/20 rounded-2xl transform -rotate-2" />
    <div className={`relative ${className}`}>{children}</div>
  </div>
);

const HomePage = () => {
  const { scrollY } = useScroll();
  const backgroundY = useTransform(scrollY, [0, 1000], [0, 300]);
  const smoothBackgroundY = useSpring(backgroundY, {
    stiffness: 100,
    damping: 30,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { content, loading } = useContent("homepage");
  const { content: siteConfig } = useContent("siteConfig");
  const { siteMode, getPath } = useSiteMode();
  const navigate = useNavigate();
  const { seoSettings } = useSeoSettings();

  // Add useEffect to handle preloading with CDN URLs
  useEffect(() => {
    if (content?.hero?.titleImage) {
      // Function to add preload link
      const addPreloadLink = (url, media) => {
        const preloadLink = document.createElement("link");
        preloadLink.rel = "preload";
        preloadLink.as = "image";
        preloadLink.href = url;
        preloadLink.setAttribute("fetchpriority", "high");
        if (media) {
          preloadLink.media = media;
        }
        document.head.appendChild(preloadLink);
        return preloadLink;
      };

      // Add mobile version
      const mobileUrl = getCdnUrl(
        content.hero.titleImage.replace(".webp", "-mobile.webp")
      );
      const mobileLink = addPreloadLink(mobileUrl, "(max-width: 768px)");

      // Add desktop version
      const desktopUrl = getCdnUrl(content.hero.titleImage);
      const desktopLink = addPreloadLink(desktopUrl, "(min-width: 769px)");

      return () => {
        document.head.removeChild(mobileLink);
        document.head.removeChild(desktopLink);
      };
    }
  }, [content?.hero?.titleImage]);

  useEffect(() => {
    if (content?.hero?.backgroundImage) {
      const cdnUrl = getCdnUrl(content.hero.backgroundImage);
      const preloadLink = document.createElement("link");
      preloadLink.rel = "preload";
      preloadLink.as = "image";
      preloadLink.href = cdnUrl;
      document.head.appendChild(preloadLink);

      return () => {
        document.head.removeChild(preloadLink);
      };
    }
  }, [content?.hero?.backgroundImage]);

  // Add loading class to body to prevent scrolling during initial load
  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [loading]);

  const handleNavigateToMission = () => {
    navigate(getPath("/about#mission"));
  };

  if (loading) {
    return (
      <>
        <Helmet>
          <link
            rel="preload"
            as="image"
            href="/TD4-logo.svg"
            fetchpriority="high"
            type="image/svg+xml"
          />
        </Helmet>
        <HomePageSkeleton />
      </>
    );
  }

  return (
    <>
      <Helmet>
        {/* Preload the SVG logo */}
        <link
          rel="preload"
          as="image"
          href="/TD4-logo.svg"
          fetchpriority="high"
          type="image/svg+xml"
        />
        {content?.hero?.titleImage && (
          <link
            rel="preload"
            as="image"
            href={getCdnUrl(content.hero.titleImage)}
            fetchpriority="high"
          />
        )}
        {content?.hero?.backgroundImage && (
          <link
            rel="preload"
            as="image"
            href={getCdnUrl(content.hero.backgroundImage)}
          />
        )}
        <title>
          {seoSettings?.pages?.home?.title ||
            "Tour De 4 - Join Sir Chris Hoy's Charity Bike Ride"}
        </title>
        <meta
          name="description"
          content={
            seoSettings?.pages?.home?.description ||
            "Be part of Sir Chris Hoy's groundbreaking charity bike ride, raising awareness and funds for Stage 4 cancer research and support."
          }
        />
        <meta name="theme-color" content={seoSettings?.metaTags?.themeColor} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoSettings?.openGraph?.url} />
        <meta
          property="og:title"
          content={
            seoSettings?.pages?.home?.title || seoSettings?.openGraph?.title
          }
        />
        <meta
          property="og:description"
          content={
            seoSettings?.pages?.home?.description ||
            seoSettings?.openGraph?.description
          }
        />
        <meta
          property="og:image"
          content={
            seoSettings?.pages?.home?.image || seoSettings?.openGraph?.image
          }
        />

        {/* Twitter */}
        <meta property="twitter:card" content={seoSettings?.twitter?.card} />
        <meta property="twitter:url" content={seoSettings?.openGraph?.url} />
        <meta
          property="twitter:title"
          content={
            seoSettings?.pages?.home?.title || seoSettings?.twitter?.title
          }
        />
        <meta
          property="twitter:description"
          content={
            seoSettings?.pages?.home?.description ||
            seoSettings?.twitter?.description
          }
        />
        <meta
          property="twitter:image"
          content={
            seoSettings?.pages?.home?.image || seoSettings?.twitter?.image
          }
        />
      </Helmet>

      {/* Main content - Force minimum height even while loading */}
      <div
        className="min-h-screen relative overflow-hidden"
        style={{ minHeight: "100vh" }}
      >
        {/* Animated Background - Show even while loading */}
        <motion.div
          className="absolute inset-0 -z-10"
          style={{
            y: smoothBackgroundY,
            background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
          }}
        />

        {/* Hero Section - Reserve space while loading */}
        <section
          className="relative min-h-screen bg-cover bg-center"
          style={{
            minHeight: "100vh",
            backgroundImage: content?.hero?.backgroundImage
              ? `linear-gradient(to bottom, rgba(15,23,42,0.6), rgba(15,23,42,1)), url(${getCdnUrl(
                  content.hero.backgroundImage
                )})`
              : `linear-gradient(to bottom, rgba(15,23,42,0.6), rgba(15,23,42,1))`,
          }}
        >
          <div
            className="relative min-h-screen flex flex-col items-center justify-center text-center px-4"
            style={{ minHeight: "100vh" }}
          >
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="max-w-4xl mx-auto space-y-6"
            >
              <div className="mb-6">
                <img
                  src="/TD4-logo.svg"
                  alt="Tour De 4"
                  className="h-48 md:h-52 w-auto mx-auto"
                  width={192}
                  height={192}
                  loading="eager"
                  fetchpriority="high"
                  decoding="async"
                  style={{
                    transform: "translateZ(0)",
                    willChange: "transform",
                    contentVisibility: "auto",
                  }}
                />
              </div>
              <h1 className="text-7xl font-bold text-white mb-6">
                TOUR DE <span style={{ color: colors.accent }}>4</span>
              </h1>
              <p className="text-3xl text-white mb-8">
                {content?.hero?.subtitle ||
                  "Riding Together Against Stage 4 Cancer"}
              </p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 rounded-full text-slate-800 font-semibold text-xl"
                style={{ background: colors.accent }}
                onClick={handleNavigateToMission}
              >
                {content?.hero?.ctaText || "Join The Movement"}
              </motion.button>
            </motion.div>
          </div>
        </section>

        {/* Sir Chris Hoy Introduction */}
        <section className="py-24 px-4">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center"
          >
            <DecorativeSection className="col-span-1 md:col-span-2">
              {content?.intro?.mediaType === "video" ? (
                <div className="relative aspect-video w-full rounded-lg overflow-hidden">
                  <video
                    src={content?.intro?.videoUrl}
                    className="absolute inset-0 w-full h-full object-cover"
                    controls
                    preload="metadata"
                    playsInline
                    webkit-playsinline="true"
                    x-webkit-airplay="allow"
                    style={{
                      colorProfile: "srgb",
                      WebkitColorProfile: "srgb",
                      colorSpace: "srgb",
                      WebkitColorSpace: "srgb",
                    }}
                    aria-label="Introduction video featuring Sir Chris Hoy"
                  >
                    <track
                      kind="captions"
                      src={
                        content?.intro?.captionsUrl || "/captions/intro-en.vtt"
                      }
                      srcLang="en"
                      label="English"
                      default
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  src={content?.intro?.image || "/Peligoni-10.webp"}
                  alt={content?.intro?.title || "Sir Chris Hoy"}
                  className="w-full rounded-lg"
                />
              )}
            </DecorativeSection>
            <div className="space-y-6">
              <h2 className="text-4xl font-bold text-white">
                {content?.intro?.title || "Sir Chris Hoy"}
              </h2>
              <p className="text-xl text-white leading-relaxed">
                {content?.intro?.description ||
                  "Following Sir Chris Hoy's diagnosis, Tour de 4 emerged as a powerful initiative to demonstrate that living well with Stage 4 cancer is possible. As a six-time Olympic champion, Chris brings his fighting spirit to this new challenge."}
              </p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                className="px-6 py-3 rounded-full text-white"
                style={{ background: colors.secondary }}
                onClick={() =>
                  (window.location.href =
                    siteMode === "development" ? "/dev/about" : "/about")
                }
              >
                {content?.intro?.ctaText || "Read Chris's Story"}
              </motion.button>
            </div>
          </motion.div>
        </section>

        {/* Quick Facts */}
        <section className="py-24 px-4 bg-white/5">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-6xl mx-auto space-y-16"
          >
            <div className="relative">
              <h2 className="text-3xl font-bold text-white text-center">
                {content?.facts?.title || "Stage 4 Cancer Awareness"}
              </h2>
              <div className="flex justify-center gap-1 mt-2">
                <div className="w-16 h-0.5 bg-secondary" />
                <div className="w-32 h-0.5 bg-accent" />
                <div className="w-16 h-0.5 bg-secondary" />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {(
                content?.facts?.items || [
                  {
                    title: "Living with Stage 4",
                    stat: "32K+",
                    desc: "People living with Stage 4 cancer in the UK",
                  },
                  {
                    title: "Research Impact",
                    stat: "£500M",
                    desc: "Annual funding needed for Stage 4 research",
                  },
                  {
                    title: "Community",
                    stat: "1 in 2",
                    desc: "People affected by cancer in their lifetime",
                  },
                ]
              ).map((fact, i) => (
                <motion.div
                  key={i}
                  initial={{ opacity: 0, y: 30 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: i * 0.2 }}
                  className="p-8 bg-white/10 backdrop-blur-sm rounded-lg text-center"
                >
                  <h3 className="text-2xl font-bold text-white mb-2">
                    {fact.stat}
                  </h3>
                  <p className="text-lg font-semibold text-white/90 mb-4">
                    {fact.title}
                  </p>
                  <p className="text-white/70">{fact.desc}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </section>

        {/* Registration CTA */}
        <section className="bg-white py-24 px-4">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="max-w-3xl mx-auto text-center space-y-8"
          >
            <h2 className="text-4xl font-bold text-secondary">
              {content?.registration?.title || "Be Part of Something Bigger"}
            </h2>
            <p className="text-xl text-primaryDark/80">
              {content?.registration?.description ||
                "Join us in this landmark cycling event to raise awareness and vital funds for Stage 4 cancer research."}
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                className="px-8 py-4 rounded-full text-slate-800 font-semibold"
                style={{ background: colors.accent }}
                onClick={() => {
                  if (siteConfig?.disableRegistrationModal) {
                    // Use homepage-specific URL if available, otherwise fall back to site-wide URL
                    const targetUrl =
                      content?.registration?.registerButtonUrl ||
                      siteConfig?.defaultRegistrationUrl;
                    if (targetUrl) {
                      window.open(targetUrl, "_blank");
                    } else {
                      setIsModalOpen(true);
                    }
                  } else {
                    setIsModalOpen(true);
                  }
                }}
              >
                {content?.registration?.registerButtonText ||
                  "Register Interest"}
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                className="px-8 py-4 rounded-full text-white"
                style={{ background: colors.secondary }}
                onClick={() =>
                  (window.location.href = "mailto:sponsorship@tourde4.com")
                }
              >
                {content?.registration?.sponsorButtonText ||
                  "Sponsor The Event"}
              </motion.button>
            </div>
          </motion.div>
        </section>

        {/* Add the modal component */}
        <RegisterInterestModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          source="homepage"
        />

        {/* Sponsors Banner */}
        <SponsorsSection sponsors={content?.sponsors} />

        {/* Social Media Integration */}
        <section className="bg-white py-16 px-4">
          <div className="max-w-6xl mx-auto text-center space-y-8">
            <h3 className="text-2xl font-bold text-secondary">
              {content?.social?.title || "Follow Our Journey"}
            </h3>
            <div className="flex justify-center space-x-8">
              {[
                {
                  Icon: Facebook,
                  label: "Facebook",
                  url: content?.social?.facebook,
                },
                { Icon: XIcon, label: "X", url: content?.social?.twitter },
                {
                  Icon: Instagram,
                  label: "Instagram",
                  url: content?.social?.instagram,
                },
              ].map(({ Icon, label, url }) => (
                <motion.a
                  key={label}
                  href={url || "#"}
                  whileHover={{ scale: 1.1 }}
                  className="text-primary-dark hover:text-secondary transition-colors duration-300 hover:underline focus:underline"
                  aria-label={`Visit our ${label} page`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon className="w-8 h-8" aria-hidden="true" />
                  <span className="sr-only">Visit our {label} page</span>
                </motion.a>
              ))}
            </div>
          </div>
        </section>

        {/* News Ticker */}
        {/* <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="fixed bottom-0 w-full bg-white/10 backdrop-blur-md py-4"
        >
          <div className="max-w-7xl mx-auto px-4 flex items-center space-x-8">
            <span className="text-white font-semibold whitespace-nowrap">
              Latest Updates:
            </span>
            <motion.div
              animate={{ x: ["100%", "-100%"] }}
              transition={{ duration: 30, repeat: Infinity, ease: "linear" }}
              className="text-white/80 whitespace-nowrap"
            >
              Registration opens next month • Training rides with Sir Chris Hoy
              announced • New corporate sponsors joining the cause • Community
              events being planned nationwide
            </motion.div>
          </div>
        </motion.div> */}
      </div>
    </>
  );
};

export default HomePage;
