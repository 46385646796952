import React, { useState, useEffect, lazy, Suspense } from "react";
import { AnimatePresence } from "framer-motion";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Link,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import SplashScreen from "./Components/SplashScreen";
import ProtectedRoute from "./Components/ProtectedRoute";
import HomePage from "./Pages/HomePage";
import { Header } from "./Components/Header";
import Footer from "./Components/Footer";
import AboutPage from "./Pages/About";
import Support from "./Pages/Support";
import Resources from "./Pages/Resources";
import Donate from "./Pages/Donate";
import Contact from "./Pages/Contact";
import NewsAndMedia from "./Pages/NewsAndMedia";
import ArticlePage from "./Pages/ArticlePage";
import MediaArticlePage from "./Pages/MediaArticlePage";
import { useSiteMode } from "./contexts/SiteModeContext";
import { SiteModeProvider } from "./contexts/SiteModeContext";
import { TabProvider } from "./contexts/TabContext";
import CookieConsentBanner from "./Components/CookieConsentBanner";
import PageTransition from "./Components/PageTransition";
import { initializeCache } from "./services/contentCache";
import { useContent } from "./hooks/useContent";

// Lazy load MainLandingPage
const MainLandingPage = lazy(() => import("./Components/MainLandingPage"));

// Lazy load components
const AdminPortal = lazy(() => import("./Components/admin/AdminPortal"));

// Lazy load GetInvolved, Privacy, Terms, and Cookies
const GetInvolved = lazy(() => import("./Pages/GetInvolved"));
const Privacy = lazy(() => import("./Pages/Privacy"));
const Terms = lazy(() => import("./Pages/Terms"));
const Cookies = lazy(() => import("./Pages/Cookies"));

// Loading fallback for lazy components
const LazyLoadingFallback = () => (
  <div className="fixed inset-0 w-full h-full flex items-center justify-center bg-[#0f172a] z-50">
    <img
      src="/TD4-logo.svg"
      alt="Loading..."
      className="w-24 h-24 animate-pulse"
      width={96}
      height={96}
      loading="eager"
      fetchpriority="high"
    />
  </div>
);

// Create ContentProvider to coordinate loading states
const ContentProvider = ({ children }) => {
  const { loading } = useContent();
  return children;
};

// Layout for the development site
const MainLayout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow">
        <PageTransition>{children}</PageTransition>
      </main>
      <Footer />
    </div>
  );
};

function AppRoutes() {
  const location = useLocation();
  const { siteMode } = useSiteMode();

  return (
    <>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          {/* Root path - conditionally render based on site mode */}
          <Route
            path="/"
            element={
              siteMode === "development" ? (
                <ProtectedRoute routeName="mainLandingPage">
                  <PageTransition>
                    <Suspense fallback={<LazyLoadingFallback />}>
                      <MainLandingPage />
                    </Suspense>
                  </PageTransition>
                </ProtectedRoute>
              ) : (
                <ProtectedRoute routeName="home">
                  <MainLayout>
                    <HomePage />
                  </MainLayout>
                </ProtectedRoute>
              )
            }
          />

          <Route
            path="/donate"
            element={
              <PageTransition>
                <Donate />
              </PageTransition>
            }
          />

          {/* Root level routes (for live mode) */}
          <Route
            path="/about"
            element={
              <ProtectedRoute routeName="about">
                <MainLayout>
                  <AboutPage />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/support"
            element={
              <ProtectedRoute routeName="support">
                <MainLayout>
                  <Support />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/resources"
            element={
              <ProtectedRoute routeName="resources">
                <MainLayout>
                  <Resources />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/get-involved"
            element={
              <ProtectedRoute routeName="getInvolved">
                <MainLayout>
                  <Suspense fallback={<LazyLoadingFallback />}>
                    <GetInvolved />
                  </Suspense>
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/get-involved/registration"
            element={
              <ProtectedRoute routeName="getInvolved">
                <MainLayout>
                  <GetInvolved />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/get-involved/fundraising"
            element={
              <ProtectedRoute routeName="getInvolved">
                <MainLayout>
                  <GetInvolved />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/contact"
            element={
              <ProtectedRoute routeName="contact">
                <MainLayout>
                  <Contact />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/news"
            element={
              <ProtectedRoute routeName="news">
                <MainLayout>
                  <NewsAndMedia />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/news/:articleId"
            element={
              <ProtectedRoute routeName="news">
                <MainLayout>
                  <ArticlePage />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/media/:mediaId"
            element={
              <ProtectedRoute routeName="news">
                <MainLayout>
                  <MediaArticlePage />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          {/* Protected development routes (with /dev prefix) */}
          <Route
            path="/dev"
            element={
              <ProtectedRoute routeName="home">
                <MainLayout>
                  <HomePage />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dev/about"
            element={
              <ProtectedRoute routeName="about">
                <MainLayout>
                  <AboutPage />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dev/support"
            element={
              <ProtectedRoute routeName="support">
                <MainLayout>
                  <Support />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dev/resources"
            element={
              <ProtectedRoute routeName="resources">
                <MainLayout>
                  <Resources />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dev/get-involved"
            element={
              <ProtectedRoute routeName="getInvolved">
                <MainLayout>
                  <GetInvolved />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dev/get-involved/registration"
            element={
              <ProtectedRoute routeName="getInvolved">
                <MainLayout>
                  <GetInvolved />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dev/get-involved/fundraising"
            element={
              <ProtectedRoute routeName="getInvolved">
                <MainLayout>
                  <GetInvolved />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dev/contact"
            element={
              <ProtectedRoute routeName="contact">
                <MainLayout>
                  <Contact />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dev/news"
            element={
              <ProtectedRoute routeName="news">
                <MainLayout>
                  <NewsAndMedia />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dev/news/:articleId"
            element={
              <ProtectedRoute routeName="news">
                <MainLayout>
                  <ArticlePage />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/dev/media/:mediaId"
            element={
              <ProtectedRoute routeName="news">
                <MainLayout>
                  <MediaArticlePage />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          {/* Protected admin portal - without MainLayout */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute routeName="adminPortal">
                <Suspense fallback={<LazyLoadingFallback />}>
                  <AdminPortal />
                </Suspense>
              </ProtectedRoute>
            }
          />

          {/* Policy Pages */}
          <Route
            path="/privacy"
            element={
              <MainLayout>
                <Privacy />
              </MainLayout>
            }
          />
          <Route
            path="/terms"
            element={
              <MainLayout>
                <Terms />
              </MainLayout>
            }
          />
          <Route
            path="/cookies"
            element={
              <MainLayout>
                <Cookies />
              </MainLayout>
            }
          />

          {/* Development Policy Pages */}
          <Route
            path="/dev/privacy"
            element={
              <MainLayout>
                <Privacy />
              </MainLayout>
            }
          />
          <Route
            path="/dev/terms"
            element={
              <MainLayout>
                <Terms />
              </MainLayout>
            }
          />
          <Route
            path="/dev/cookies"
            element={
              <MainLayout>
                <Cookies />
              </MainLayout>
            }
          />

          {/* 404 page */}
          <Route
            path="*"
            element={
              <PageTransition>
                <div className="min-h-screen bg-slate-800 text-white p-8">
                  <h1 className="text-4xl">404 - Not Found</h1>
                  <p>Path: {location.pathname}</p>
                  <Link to="/" className="text-blue-400 hover:underline">
                    Go Home
                  </Link>
                </div>
              </PageTransition>
            }
          />
        </Routes>
      </AnimatePresence>
    </>
  );
}

function App() {
  const [loading, setLoading] = useState(!localStorage.getItem("hasVisited"));
  const [imageLoaded, setImageLoaded] = useState(true); // Start true since we have base64

  useEffect(() => {
    // Preload the high quality version
    const img = new Image();
    img.src = "/optimized/TD4-colour.webp";

    // Initialize content cache in parallel
    Promise.all([
      new Promise((resolve) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = resolve;
        }
      }),
      initializeCache(),
    ]).then(() => {
      if (loading) {
        localStorage.setItem("hasVisited", "true");
      }
    });
  }, [loading]);

  useEffect(() => {
    // Preload the GetInvolved page since it's a key page
    import("./Pages/GetInvolved");
  }, []);

  return (
    <HelmetProvider>
      <SiteModeProvider>
        <TabProvider>
          <ContentProvider>
            <Router>
              <div className="bg-[#0f172a] min-h-screen">
                {loading ? (
                  <SplashScreen finishLoading={() => setLoading(false)} />
                ) : (
                  <AppRoutes />
                )}
                <CookieConsentBanner />
              </div>
            </Router>
          </ContentProvider>
        </TabProvider>
      </SiteModeProvider>
    </HelmetProvider>
  );
}

export default App;
