import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Menu, X, ChevronDown } from "lucide-react";
import { useSiteMode } from "../hooks/useSiteMode";
import { Link, useNavigate, useLocation } from "react-router-dom";
import RegisterInterestModal from "./RegisterInterestModal";
import { useTab } from "../contexts/TabContext";
import { useContent } from "../hooks/useContent";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

export const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isOverLight, setIsOverLight] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const observerRef = useRef(null);
  const { getPath, loading } = useSiteMode();
  const navigate = useNavigate();
  const { setSelectedTab, setResourcesTab } = useTab();
  const location = useLocation();
  const { content: siteConfig } = useContent("siteConfig");

  const navItems = [
    {
      title: "About",
      subitems: [
        { name: "The Mission", href: "/about#mission" },
        { name: "The Event", href: "/about#event" },
      ],
    },
    {
      title: "Get Involved",
      subitems: [
        { name: "Registration", href: "/get-involved#registration" },
        { name: "Fundraising", href: "/get-involved#fundraising" },
      ],
    },
    {
      title: "Support",
      subitems: [
        { name: "Sponsors", href: "/support#sponsors" },
        { name: "Charities", href: "/support#charities" },
      ],
    },
    {
      title: "Resources",
      subitems: [
        { name: "Preparation", href: "/resources#preparation" },
        // { name: "Travel & Accommodation", href: "/resources#travel" },
      ],
    },
    {
      title: "News & Media",
      href: "/news",
    },
    {
      title: "Contact",
      href: "/contact",
    },
  ];

  useEffect(() => {
    // Create sentinel element for background detection
    const sentinel = document.createElement("div");
    sentinel.className = "header-sentinel";
    document.body.appendChild(sentinel);

    // Style the sentinel
    Object.assign(sentinel.style, {
      position: "fixed",
      top: "64px",
      left: "50%",
      transform: "translateX(-50%)",
      width: "100%",
      height: "10px",
      zIndex: "49",
      pointerEvents: "none",
    });

    // Create and setup intersection observer
    observerRef.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const element = entry.target;

            // Skip small UI elements
            const rect = element.getBoundingClientRect();
            if (rect.width < window.innerWidth * 0.5 || rect.height < 100) {
              return;
            }

            // Get computed background color
            const computedStyle = window.getComputedStyle(element);
            const bgColor = computedStyle.backgroundColor;

            // Skip if background is transparent or not set
            if (bgColor === "transparent") {
              return;
            }

            // Parse rgba or rgb values
            let r,
              g,
              b,
              a = 1;
            if (bgColor.startsWith("rgba")) {
              const matches = bgColor.match(
                /rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)/
              );
              if (!matches) return;
              [, r, g, b, a] = matches.map(Number);
            } else {
              const matches = bgColor.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/);
              if (!matches) return;
              [, r, g, b] = matches.map(Number);
            }

            // Calculate perceived brightness (using relative luminance formula)
            // For semi-transparent backgrounds, blend with dark background
            const alpha = a;
            const blendedR = r * alpha + 15 * (1 - alpha); // 15 is from backgroundDark
            const blendedG = g * alpha + 23 * (1 - alpha); // 23 is from backgroundDark
            const blendedB = b * alpha + 42 * (1 - alpha); // 42 is from backgroundDark

            const brightness =
              (0.299 * blendedR + 0.587 * blendedG + 0.114 * blendedB) / 255;

            // Adjust the threshold to better handle semi-transparent whites
            const shouldBeLight = brightness > 0.6;
            setIsOverLight(shouldBeLight);
          }
        });
      },
      {
        threshold: [0.1],
        rootMargin: "-64px 0px 0px 0px",
      }
    );

    // Observe only main content sections
    const elementsToObserve = document.querySelectorAll(
      'main, section, div[class*="bg-"]:not(button):not(a), [class*="container"], [class*="content"]'
    );
    elementsToObserve.forEach((element) => {
      // Additional size check before starting to observe
      const rect = element.getBoundingClientRect();
      if (rect.width >= window.innerWidth * 0.5 && rect.height >= 100) {
        observerRef.current.observe(element);
      }
    });

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
      sentinel.remove();
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Add this useEffect to handle hash scrolling after navigation
  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.slice(1);
      setTimeout(() => {
        const element = document.getElementById(id);
        element?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [window.location.pathname, window.location.hash]);

  const handleNavigation = (href) => {
    if (href.includes("#")) {
      const [path, hash] = href.split("#");
      const fullPath = getPath(path || "/");

      // If we're already on the correct page
      if (window.location.pathname === fullPath || path === "/") {
        // For Get Involved page, handle tab switching
        if (fullPath.includes("/get-involved")) {
          if (hash === "registration") {
            setSelectedTab("registration");
          } else if (hash === "fundraising") {
            setSelectedTab("fundraising");
          }
        }
        // For Resources page, handle tab switching
        if (fullPath.includes("/resources")) {
          if (hash === "preparation") {
            setResourcesTab("preparation");
          } else if (hash === "travel") {
            setResourcesTab("travel");
          }
        }
        // Scroll to the section
        const element = document.getElementById(hash);
        element?.scrollIntoView({ behavior: "smooth" });
      } else {
        // Navigate to new page with hash
        navigate(`${fullPath}#${hash}`);
      }
    } else {
      // For non-hash URLs, navigate and scroll to top
      navigate(getPath(href));
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setIsMobileMenuOpen(false);
  };

  if (loading) {
    return null; // or a loading spinner if you prefer
  }

  return (
    <header
      className={`fixed top-0 w-screen left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled
          ? isOverLight
            ? "bg-background-dark/90 backdrop-blur-sm py-4"
            : "bg-background-dark/50 backdrop-blur-sm py-4"
          : "bg-transparent py-6"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 relative">
        <nav className="flex items-center justify-between">
          <Link to={getPath("/")} className="flex items-center">
            <motion.img
              whileHover={{ scale: 1.05 }}
              src="/TD4-logo.svg"
              alt="Tour De Four Logo"
              className="h-12 w-auto"
              width={48}
              height={48}
              loading="eager"
              fetchpriority="high"
              decoding="async"
              style={{
                transform: "translateZ(0)",
                willChange: "transform",
                contentVisibility: "auto",
              }}
            />
          </Link>

          <div className="hidden lg:flex items-center space-x-8">
            {navItems.map((item) => (
              <div key={item.title} className="relative group">
                <button
                  onClick={() => item.href && handleNavigation(item.href)}
                  className="text-white hover:text-white flex items-center space-x-1 hover:underline focus:underline"
                >
                  <span>{item.title}</span>
                  {item.subitems && <ChevronDown className="w-4 h-4" />}
                </button>

                {item.subitems && (
                  <div className="absolute top-full left-0 mt-2 w-48 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200">
                    <div className="bg-background-dark/95 backdrop-blur-md rounded-lg shadow-lg p-1.5">
                      {item.subitems.map((subitem) => (
                        <button
                          key={subitem.name}
                          onClick={() => handleNavigation(subitem.href)}
                          className="block w-full text-left px-4 py-2 text-white hover:text-white hover:bg-white/10 hover:underline focus:underline rounded-md transition-colors duration-200"
                        >
                          {subitem.name}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="hidden lg:block px-6 py-2 rounded-full text-slate-800 font-semibold"
            style={{ background: colors.accent }}
            onClick={() => {
              if (siteConfig?.defaultRegistrationUrl) {
                window.open(siteConfig.defaultRegistrationUrl, "_blank");
              } else {
                setIsModalOpen(true);
              }
            }}
          >
            Register
          </motion.button>

          <button
            className="lg:hidden text-white"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
            aria-expanded={isMobileMenuOpen}
            aria-controls="mobile-menu"
          >
            {isMobileMenuOpen ? (
              <X className="w-6 h-6" aria-hidden="true" />
            ) : (
              <Menu className="w-6 h-6" aria-hidden="true" />
            )}
          </button>
        </nav>

        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div
              id="mobile-menu"
              role="navigation"
              aria-label="Mobile navigation"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              className="lg:hidden"
            >
              <div className="py-4 mt-4 bg-background-dark/95 backdrop-blur-md rounded-lg shadow-lg">
                {navItems.map((item) => (
                  <div key={item.title} className="py-2">
                    <button
                      onClick={() => item.href && handleNavigation(item.href)}
                      className="w-full text-left px-6 text-white/90 font-medium hover:text-white transition-colors duration-200"
                    >
                      {item.title}
                    </button>
                    {item.subitems && (
                      <div className="ml-4 mt-2 space-y-2 border-l border-white/5">
                        {item.subitems.map((subitem) => (
                          <button
                            key={subitem.name}
                            onClick={() => handleNavigation(subitem.href)}
                            className="block w-full text-left pl-6 pr-4 py-1 text-white/70 hover:text-white hover:bg-white/5 transition-colors duration-200"
                          >
                            {subitem.name}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="mt-4 mx-4 w-[calc(100%-2rem)] px-6 py-2 rounded-full text-slate-800 font-semibold"
                  style={{ background: colors.accent }}
                  onClick={() => {
                    if (siteConfig?.defaultRegistrationUrl) {
                      window.open(siteConfig.defaultRegistrationUrl, "_blank");
                    } else {
                      setIsModalOpen(true);
                    }
                  }}
                >
                  Register Now
                </motion.button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <RegisterInterestModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        source="header"
      />
    </header>
  );
};
