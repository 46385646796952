import React, { useEffect } from "react";
import { Calendar, CheckCircle, Bike, Heart, ChevronRight } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useContent } from "../hooks/useContent";
import { useTab } from "../contexts/TabContext";
import { useLocation } from "react-router-dom";
import { useSeoSettings } from "../hooks/useSeoSettings";
import { Helmet } from "react-helmet-async";

// Custom Card Components
const Card = ({ children, className = "" }) => (
  <div className={`relative rounded-lg p-6 ${className}`}>{children}</div>
);

const CardHeader = ({ children }) => <div className="mb-4">{children}</div>;

const CardTitle = ({ children }) => (
  <h3 className="text-xl font-semibold">{children}</h3>
);

const CardContent = ({ children, className = "" }) => (
  <div className={className}>{children}</div>
);

const DecorativeSection = ({ children, className = "" }) => (
  <div className="relative">
    <div className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-accent/20 rounded-2xl transform -rotate-2" />
    <div className={`relative ${className}`}>{children}</div>
  </div>
);

const Resources = () => {
  const { resourcesTab, setResourcesTab } = useTab();
  const { content, loading } = useContent("resources");
  const location = useLocation();
  const { seoSettings } = useSeoSettings();

  useEffect(() => {
    // Handle tab selection based on URL hash
    const hash = location.hash.slice(1);
    if (hash) {
      setResourcesTab(hash);
    }
  }, [location, setResourcesTab]);

  const renderContent = () => {
    return (
      <AnimatePresence mode="wait">
        {resourcesTab === "preparation" ? (
          <motion.div
            key="preparation"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-12"
          >
            {/* Zwift Training Section */}
            <DecorativeSection>
              <section
                id="zwift-training"
                className="bg-white rounded-xl p-8 shadow-lg"
              >
                <div className="flex flex-col md:flex-row md:items-center mb-6">
                  <div className="flex items-center mb-4 md:mb-0 md:mr-4">
                    <img
                      src="/sponsors/zwift.png"
                      alt="Zwift Logo"
                      className="h-12 w-auto"
                    />
                  </div>
                  <div>
                    <h2 className="text-3xl font-bold">Training with Zwift</h2>
                    <p className="text-gray-600 mt-2">
                      Join our official partner Zwift for structured training
                      sessions designed specifically for Tour De 4 participants.
                    </p>
                  </div>
                </div>
                <a
                  href="https://www.zwift.com/uk/events/series/tour-de-4"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block mb-8 px-6 py-3 bg-secondary text-white rounded-lg hover:bg-secondary/90 transition-colors"
                >
                  Sign Up for Zwift Training Sessions
                </a>

                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                  {loading ? (
                    <div className="col-span-full text-center py-8">
                      Loading training dates...
                    </div>
                  ) : (
                    content?.trainingDates?.map((session, index) => (
                      <Card key={index} className="bg-gray-50">
                        <CardContent className="pt-6">
                          <div className="flex flex-col space-y-3">
                            <div className="flex items-start space-x-4">
                              <Calendar className="w-5 h-5 text-secondary flex-shrink-0" />
                              <div>
                                <p className="font-semibold">{session.date}</p>
                                <p className="text-sm text-gray-600">
                                  {session.time}
                                </p>
                              </div>
                            </div>
                            <div className="flex items-start space-x-4">
                              <Bike className="w-5 h-5 text-secondary flex-shrink-0" />
                              <div>
                                <p className="text-sm font-medium text-secondary">
                                  {session.type}
                                </p>
                                <p className="text-sm text-gray-600">
                                  Route: {session.route}
                                </p>
                                <p className="text-sm text-gray-600">
                                  Duration: {session.duration}
                                </p>
                              </div>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    ))
                  )}
                </div>
              </section>
            </DecorativeSection>

            {/* Resources Section */}
            <section
              id="resources"
              className="bg-gradient-to-br from-background-light to-background-dark text-white rounded-xl p-8"
            >
              <h2 className="text-3xl font-bold mb-6">
                What To Expect From Registration
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                {[
                  {
                    title: "Equipment Checklist",
                    icon: Bike,
                    items: [
                      "Bike maintenance",
                      "Required gear",
                      "Recommended spares",
                    ],
                  },
                  {
                    title: "Nutrition Guide",
                    icon: Heart,
                    items: [
                      "Pre-ride meals",
                      "During ride nutrition",
                      "Recovery foods",
                    ],
                  },
                  {
                    title: "Safety Guidelines",
                    icon: CheckCircle,
                    items: [
                      "Road safety rules",
                      "Emergency contacts",
                      "Weather preparation",
                    ],
                  },
                ].map((resource, index) => (
                  <Card key={index} className="bg-white/10 backdrop-blur">
                    <CardHeader>
                      <resource.icon className="w-8 h-8 text-accent mb-2" />
                      <CardTitle className="text-xl text-white">
                        {resource.title}
                      </CardTitle>
                    </CardHeader>
                    <CardContent>
                      <ul className="space-y-3">
                        {resource.items.map((item, i) => (
                          <li key={i} className="flex items-center">
                            <CheckCircle className="w-4 h-4 mr-2 text-accent" />
                            <span className="text-white/90">{item}</span>
                          </li>
                        ))}
                      </ul>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </section>
          </motion.div>
        ) : null}
      </AnimatePresence>
    );
  };

  // Update the tab navigation section
  const tabs = [
    { id: "preparation", label: "Preparation" },
    // { id: "travel", label: "Travel & Accommodation" },
  ];

  if (loading) {
    return (
      <div className="min-h-screen bg-slate-800 flex items-center justify-center">
        <div className="text-white">Loading...</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {seoSettings?.pages?.resources?.title || "Resources - Tour De 4"}
        </title>
        <meta
          name="description"
          content={
            seoSettings?.pages?.resources?.description ||
            "Access helpful resources and information about Stage 4 cancer, cycling, and support services."
          }
        />
        <meta name="theme-color" content={seoSettings?.metaTags?.themeColor} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoSettings?.openGraph?.url} />
        <meta
          property="og:title"
          content={
            seoSettings?.pages?.resources?.title ||
            seoSettings?.openGraph?.title
          }
        />
        <meta
          property="og:description"
          content={
            seoSettings?.pages?.resources?.description ||
            seoSettings?.openGraph?.description
          }
        />
        <meta
          property="og:image"
          content={
            seoSettings?.pages?.resources?.image ||
            seoSettings?.openGraph?.image
          }
        />

        {/* Twitter */}
        <meta property="twitter:card" content={seoSettings?.twitter?.card} />
        <meta property="twitter:url" content={seoSettings?.openGraph?.url} />
        <meta
          property="twitter:title"
          content={
            seoSettings?.pages?.resources?.title || seoSettings?.twitter?.title
          }
        />
        <meta
          property="twitter:description"
          content={
            seoSettings?.pages?.resources?.description ||
            seoSettings?.twitter?.description
          }
        />
        <meta
          property="twitter:image"
          content={
            seoSettings?.pages?.resources?.image || seoSettings?.twitter?.image
          }
        />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="min-h-screen bg-white"
      >
        {/* Hero Section */}
        <div className="relative bg-[#0f172a]">
          <div className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-accent/20 opacity-50" />
          <div className="max-w-4xl mx-auto px-4 py-24 md:py-32">
            <h1 className="text-5xl md:text-6xl font-bold text-white mb-6">
              Resources
            </h1>
            <p className="text-lg sm:text-xl md:text-2xl text-white/80 max-w-3xl">
              Everything you need to prepare for and participate in Tour De 4
            </p>
          </div>
        </div>

        {/* Main Content */}
        <div className="max-w-7xl mx-auto px-4 py-12">
          {/* Tab Navigation */}
          <div
            id="resources-nav"
            className="bg-white sticky top-0 z-10 mb-12 rounded-lg overflow-hidden"
          >
            <div className="max-w-7xl mx-auto px-6">
              <div className="flex space-x-8 -mb-px overflow-x-auto no-scrollbar">
                {tabs.map((tab) => (
                  <button
                    key={tab.id}
                    ref={(el) => {
                      // Store reference to button element
                      if (el && resourcesTab === tab.id) {
                        setTimeout(() => {
                          el.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "center",
                          });
                        }, 100);
                      }
                    }}
                    onClick={(e) => {
                      setResourcesTab(tab.id);
                      window.history.pushState(null, "", `#${tab.id}`);
                      const element = document.getElementById(tab.id);
                      element?.scrollIntoView({ behavior: "smooth" });
                      // Scroll the tab into view
                      e.currentTarget.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                        inline: "center",
                      });
                    }}
                    className={`py-4 px-1 border-b-2 font-medium text-lg transition-colors whitespace-nowrap flex-shrink-0 ${
                      resourcesTab === tab.id
                        ? "border-secondary text-secondary"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                    }`}
                  >
                    {tab.label}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Content Sections */}
          {renderContent()}
        </div>
      </motion.div>
    </>
  );
};

// Add this CSS at the top of the file after the imports
const style = document.createElement("style");
style.textContent = `
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-x: hidden;
    scrollbar-width: none;
  }
`;
document.head.appendChild(style);

export default Resources;
