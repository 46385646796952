const isDevelopment = process.env.NODE_ENV === "development";

export const logger = {
  info: (...args) => {
    if (isDevelopment) {
      console.log(...args);
    }
  },

  error: (...args) => {
    if (isDevelopment) {
      console.error(...args);
    }
  },

  warn: (...args) => {
    if (isDevelopment) {
      console.warn(...args);
    }
  },

  // For performance logging that we might want in production
  performance: (...args) => {
    console.log(...args);
  },

  // For critical errors that we want to log in production
  critical: (...args) => {
    console.error(...args);
  },
};
