import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useContent } from "../hooks/useContent";

const SplashScreen = ({ finishLoading }) => {
  const controls = useAnimation();
  const { loading: contentLoading } = useContent();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if device is mobile (width less than 768px)
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Check initially
    checkMobile();

    // Add resize listener
    window.addEventListener("resize", checkMobile);

    // Cleanup
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    // If mobile, skip animation and finish loading immediately
    if (isMobile && !contentLoading) {
      finishLoading();
      return;
    }

    // Only start animation sequence when content is ready and not on mobile
    if (!contentLoading && !isMobile) {
      controls
        .start({
          opacity: [0, 1, 1, 0],
          scale: [0.8, 1, 1, 0.95],
          transition: {
            duration: 1.5,
            times: [0, 0.2, 0.8, 1],
            ease: "easeOut",
          },
        })
        .then(finishLoading);
    }
  }, [controls, finishLoading, contentLoading, isMobile]);

  // If mobile, don't render splash screen at all
  if (isMobile) {
    return null;
  }

  return (
    <div className="fixed inset-0 w-full h-full flex items-center justify-center bg-[#0f172a] z-50">
      <motion.div
        animate={controls}
        initial={{ opacity: 0, scale: 0.8 }}
        style={{
          willChange: "transform, opacity",
          transform: "translateZ(0)",
          backfaceVisibility: "hidden",
        }}
      >
        <img
          src="/TD4-logo.svg"
          alt="Tour De Four Logo"
          className="w-64 h-64 object-contain"
          width={256}
          height={256}
          loading="eager"
          fetchpriority="high"
          decoding="sync"
          style={{
            transform: "translateZ(0)",
            willChange: "transform",
            contentVisibility: "auto",
          }}
        />
      </motion.div>
    </div>
  );
};

export default SplashScreen;
