import React from "react";
import { motion } from "framer-motion";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

const HomePageSkeleton = () => {
  return (
    <div className="min-h-screen relative overflow-hidden">
      {/* Animated Background */}
      <div
        className="absolute inset-0 -z-10"
        style={{
          background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
        }}
      />

      {/* Hero Section Skeleton */}
      <section
        className="relative min-h-screen bg-cover bg-center"
        style={{
          minHeight: "100vh",
          backgroundImage: `linear-gradient(to bottom, rgba(15,23,42,0.6), rgba(15,23,42,1))`,
        }}
      >
        <div
          className="relative min-h-screen flex flex-col items-center justify-center text-center px-4"
          style={{ minHeight: "100vh" }}
        >
          <div className="max-w-4xl mx-auto space-y-6">
            {/* Logo Skeleton */}
            <div className="mb-6 flex justify-center">
              <div className="h-48 md:h-52 w-48 md:w-52 bg-white/10 animate-pulse rounded-lg" />
            </div>
            {/* Title Skeleton */}
            <div className="h-20 bg-white/10 animate-pulse rounded-lg mb-6" />
            {/* Subtitle Skeleton */}
            <div className="h-12 bg-white/10 animate-pulse rounded-lg mb-8 max-w-2xl mx-auto" />
            {/* CTA Button Skeleton */}
            <div className="flex justify-center">
              <div className="h-14 w-48 bg-accent/50 animate-pulse rounded-full" />
            </div>
          </div>
        </div>
      </section>

      {/* Sir Chris Hoy Introduction Skeleton */}
      <section className="py-24 px-4">
        <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          {/* Video/Image Skeleton */}
          <div className="col-span-1 md:col-span-2">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-accent/20 rounded-2xl transform -rotate-2" />
              <div className="relative">
                <div className="aspect-video w-full rounded-lg bg-white/10 animate-pulse" />
              </div>
            </div>
          </div>
          {/* Text Content Skeleton */}
          <div className="space-y-6">
            <div className="h-10 bg-white/10 animate-pulse rounded-lg w-3/4" />
            <div className="space-y-4">
              <div className="h-6 bg-white/10 animate-pulse rounded-lg" />
              <div className="h-6 bg-white/10 animate-pulse rounded-lg w-5/6" />
              <div className="h-6 bg-white/10 animate-pulse rounded-lg w-4/5" />
            </div>
            <div className="h-12 w-40 bg-secondary/50 animate-pulse rounded-full" />
          </div>
        </div>
      </section>

      {/* Quick Facts Skeleton */}
      <section className="py-24 px-4 bg-white/5">
        <div className="max-w-6xl mx-auto space-y-16">
          {/* Section Title Skeleton */}
          <div className="relative">
            <div className="h-10 bg-white/10 animate-pulse rounded-lg w-64 mx-auto mb-4" />
            <div className="flex justify-center gap-1 mt-2">
              <div className="w-16 h-0.5 bg-secondary/50" />
              <div className="w-32 h-0.5 bg-accent/50" />
              <div className="w-16 h-0.5 bg-secondary/50" />
            </div>
          </div>

          {/* Facts Grid Skeleton */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[1, 2, 3].map((i) => (
              <div
                key={i}
                className="p-8 bg-white/10 backdrop-blur-sm rounded-lg text-center space-y-4"
              >
                <div className="h-8 bg-white/20 animate-pulse rounded-lg w-24 mx-auto" />
                <div className="h-6 bg-white/20 animate-pulse rounded-lg w-32 mx-auto" />
                <div className="h-16 bg-white/20 animate-pulse rounded-lg w-full" />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Registration CTA Skeleton */}
      <section className="bg-white py-24 px-4">
        <div className="max-w-3xl mx-auto text-center space-y-8">
          <div className="h-10 bg-secondary/20 animate-pulse rounded-lg w-72 mx-auto" />
          <div className="h-16 bg-primaryDark/10 animate-pulse rounded-lg max-w-xl mx-auto" />
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <div className="h-14 w-48 bg-accent/30 animate-pulse rounded-full" />
            <div className="h-14 w-48 bg-secondary/30 animate-pulse rounded-full" />
          </div>
        </div>
      </section>

      {/* Sponsors Banner Skeleton */}
      <section className="bg-white py-16 px-4">
        <div className="max-w-6xl mx-auto text-center space-y-12">
          <div className="h-8 bg-secondary/20 animate-pulse rounded-lg w-48 mx-auto" />
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 items-center justify-items-center max-w-4xl mx-auto">
            {[1, 2, 3, 4].map((i) => (
              <div
                key={i}
                className="h-20 w-40 bg-gray-100/50 animate-pulse rounded-lg"
              />
            ))}
          </div>
        </div>
      </section>

      {/* Social Media Integration Skeleton */}
      <section className="bg-white py-16 px-4">
        <div className="max-w-6xl mx-auto text-center space-y-8">
          <div className="h-8 bg-secondary/20 animate-pulse rounded-lg w-48 mx-auto" />
          <div className="flex justify-center space-x-8">
            {[1, 2, 3].map((i) => (
              <div
                key={i}
                className="h-8 w-8 bg-primaryDark/20 animate-pulse rounded-full"
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePageSkeleton;
