import { useState, useEffect } from "react";
import { getRouteProtectionSettings } from "../firebase/routeProtections";

export const useSiteMode = () => {
  const [siteMode, setSiteMode] = useState("development");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSiteMode = async () => {
      try {
        const settings = await getRouteProtectionSettings();
        setSiteMode(settings.siteMode);
      } catch (error) {
        console.error("Error fetching site mode:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSiteMode();
  }, []);

  const getPath = (path) => {
    if (siteMode === "development") {
      // In development mode, add /dev prefix if not already present
      return path.startsWith("/dev/") ? path : `/dev${path}`;
    } else {
      // In live mode, remove /dev prefix if present
      return path.replace("/dev/", "/");
    }
  };

  return { siteMode, loading, getPath };
};
