import React, { useState } from "react";
import { Mail, ArrowDown, ExternalLink } from "lucide-react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useContent } from "../hooks/useContent";
import { useSeoSettings } from "../hooks/useSeoSettings";
import { Helmet } from "react-helmet-async";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

const SponsorCharityPage = () => {
  const { content, loading, error } = useContent("support");
  const { seoSettings } = useSeoSettings();
  const [pageRef, setPageRef] = useState(null);
  const { scrollYProgress } = useScroll({
    target: pageRef,
    offset: ["start start", "end end"],
  });

  const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "25%"]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!content) return <div>No content available</div>;

  const { sponsors, charities, distribution, partnershipCta } = content;

  return (
    <>
      <Helmet>
        <title>
          {seoSettings?.pages?.support?.title ||
            "Support Tour De 4 - Make a Difference"}
        </title>
        <meta
          name="description"
          content={
            seoSettings?.pages?.support?.description ||
            "Support Tour De 4's mission to raise awareness and funds for Stage 4 cancer research and support services."
          }
        />
        <meta name="theme-color" content={seoSettings?.metaTags?.themeColor} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoSettings?.openGraph?.url} />
        <meta
          property="og:title"
          content={
            seoSettings?.pages?.support?.title || seoSettings?.openGraph?.title
          }
        />
        <meta
          property="og:description"
          content={
            seoSettings?.pages?.support?.description ||
            seoSettings?.openGraph?.description
          }
        />
        <meta
          property="og:image"
          content={
            seoSettings?.pages?.support?.image || seoSettings?.openGraph?.image
          }
        />

        {/* Twitter */}
        <meta property="twitter:card" content={seoSettings?.twitter?.card} />
        <meta property="twitter:url" content={seoSettings?.openGraph?.url} />
        <meta
          property="twitter:title"
          content={
            seoSettings?.pages?.support?.title || seoSettings?.twitter?.title
          }
        />
        <meta
          property="twitter:description"
          content={
            seoSettings?.pages?.support?.description ||
            seoSettings?.twitter?.description
          }
        />
        <meta
          property="twitter:image"
          content={
            seoSettings?.pages?.support?.image || seoSettings?.twitter?.image
          }
        />
      </Helmet>

      <div className="min-h-screen relative bg-white" ref={setPageRef}>
        {/* Parallax Background - Base layer */}
        <motion.div
          className="fixed w-full pointer-events-none md:bg-[center] bg-[center_15vh]"
          style={{
            y: backgroundY,
            backgroundImage: `url('/TD4-PAR.svg')`,
            backgroundSize: "80%",
            backgroundRepeat: "no-repeat",
            opacity: 0.23,
            backgroundColor: "transparent",
            top: 0,
            height: "200vh",
            left: 0,
            right: 0,
            zIndex: 0,
            filter:
              "invert(52%) sepia(95%) saturate(401%) hue-rotate(143deg) brightness(89%) contrast(89%)",
          }}
        />

        {/* Content Container - All content above SVG */}
        <div className="relative z-10">
          {/* Hero Section */}
          <motion.div
            className="min-h-[50vh] flex items-center justify-center relative overflow-hidden"
            style={{
              background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
            }}
          >
            {/* Decorative Background Element */}
            <motion.div
              className="absolute top-20 left-20 w-96 h-96 rounded-full opacity-10"
              style={{
                background: colors.secondary,
                filter: "blur(60px)",
              }}
              animate={{
                scale: [1, 1.2, 1],
                rotate: [0, 90, 0],
              }}
              transition={{
                duration: 20,
                ease: "linear",
                repeat: Infinity,
              }}
            />

            <div className="max-w-7xl mx-auto px-8 text-center relative">
              <motion.div
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.4, delay: 0.2 }}
                className="space-y-8"
              >
                <h1 className="text-5xl md:text-6xl font-bold text-white mb-8">
                  {sponsors?.title || "Our Partners"}
                </h1>
                <p className="text-2xl text-white/70 max-w-3xl mx-auto">
                  {sponsors?.description ||
                    "Working together to make a difference in the lives of those affected by stage 4 cancer."}
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.8 }}
                className="mt-12"
              >
                <ArrowDown className="w-8 h-8 text-white/50 mx-auto animate-bounce" />
              </motion.div>
            </div>
          </motion.div>

          {/* Individual Partner Sections */}
          <div className="relative">
            {sponsors?.items?.map((sponsor, index) => (
              <div
                key={sponsor.name}
                className={`min-h-screen flex items-center relative ${
                  index % 2 === 0 ? "bg-white/80" : "bg-gray-50/80"
                }`}
              >
                <div className="max-w-7xl mx-auto px-8 py-24">
                  <div className="grid md:grid-cols-2 gap-24 items-center">
                    {/* Logo and Description */}
                    <div
                      className={`space-y-12 ${
                        index % 2 === 1 ? "md:order-2" : ""
                      }`}
                    >
                      <img
                        src={sponsor.logo}
                        alt={sponsor.name}
                        className="w-full max-w-md mx-auto"
                      />
                      <div className="space-y-6">
                        <p className="text-xl text-slate-600 leading-relaxed">
                          {sponsor.description}
                        </p>
                        <div className="space-y-3">
                          {sponsor.features?.map((feature, i) => (
                            <div
                              key={i}
                              className="flex items-center text-slate-700"
                            >
                              <div
                                className="w-2 h-2 rounded-full mr-3"
                                style={{ backgroundColor: colors.secondary }}
                              ></div>
                              {feature}
                            </div>
                          ))}
                        </div>
                        <a
                          href={sponsor.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center px-8 py-4 rounded-full text-white text-lg transition-all hover:scale-105"
                          style={{ backgroundColor: colors.secondary }}
                        >
                          Visit Website
                          <ExternalLink className="w-5 h-5 ml-2" />
                        </a>
                      </div>
                    </div>

                    {/* Image Gallery */}
                    <div
                      className={`grid gap-6 ${
                        index % 2 === 1 ? "md:order-1" : ""
                      }`}
                    >
                      {sponsor.images?.map((image, i) => (
                        <div
                          key={i}
                          className={`rounded-2xl overflow-hidden shadow-lg ${
                            i === 0 ? "md:col-span-2" : "md:col-span-1"
                          }`}
                        >
                          <img
                            src={image}
                            alt={`${sponsor.name} showcase ${i + 1}`}
                            className="w-full h-full object-cover"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Become a Sponsor Section */}
          <div className="py-32 relative bg-gray-50">
            <div className="max-w-7xl mx-auto px-8 text-center">
              <h2 className="text-6xl font-bold text-slate-800 mb-8">
                {partnershipCta?.title || "Become a Partner"}
              </h2>
              <p className="text-xl text-slate-600 max-w-3xl mx-auto mb-16">
                {partnershipCta?.description ||
                  "If you're interested in exploring Tour de 4 sponsorship packages, please get in touch"}
              </p>
              <a
                href={`mailto:${
                  partnershipCta?.email || "sponsors@tourde4.com"
                }`}
                className="inline-flex items-center px-14 py-6 rounded-full text-slate-800 font-semibold text-lg transition-all hover:scale-105"
                style={{ backgroundColor: colors.accent }}
              >
                <Mail className="w-6 h-6 mr-3" />
                {partnershipCta?.buttonText || "Get In Touch"}
              </a>
            </div>
          </div>

          {/* Charities Section */}
          <div id="charities" className="bg-white">
            {/* Charities Intro */}
            <div
              style={{
                background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
              }}
              className="py-32"
            >
              <div className="max-w-7xl mx-auto px-8 text-center">
                <h2 className="text-6xl font-bold text-white mb-8">
                  {charities?.title || "Supporting Charities"}
                </h2>
                <p className="text-xl text-white/70 max-w-3xl mx-auto">
                  {charities?.description ||
                    "Every donation helps fund vital research and support services for those affected by cancer."}
                </p>
              </div>
            </div>

            {/* Individual Charity Sections */}
            <div>
              {charities?.items?.map((charity, index) => (
                <div
                  key={charity.name}
                  className={`py-24 ${
                    index % 2 === 0 ? "bg-white" : "bg-gray-50"
                  }`}
                >
                  <div className="max-w-7xl mx-auto px-8">
                    <div className="grid md:grid-cols-2 gap-16 items-center">
                      <div className={index % 2 === 0 ? "" : "md:order-2"}>
                        {/* Logo and Content */}
                        <div className="space-y-8">
                          <img
                            src={charity.logo}
                            alt={charity.name}
                            className="mb-8"
                          />
                          <p className="text-2xl font-medium text-slate-600 italic">
                            "{charity.tagline}"
                          </p>
                          <p className="text-lg text-slate-600 leading-relaxed">
                            {charity.description}
                          </p>

                          {/* Impact Stats */}
                          <div className="grid grid-cols-3 gap-4 py-8">
                            {charity.impact?.map((item, i) => (
                              <div key={i} className="text-center">
                                <div
                                  className="text-3xl font-bold mb-2"
                                  style={{ color: colors.secondary }}
                                >
                                  {item.stat}
                                </div>
                                <div className="text-sm text-slate-600">
                                  {item.label}
                                </div>
                              </div>
                            ))}
                          </div>

                          {/* Highlights */}
                          <div className="space-y-3">
                            {charity.highlights?.map((highlight, i) => (
                              <div
                                key={i}
                                className="flex items-center text-slate-700"
                              >
                                <div
                                  className="w-2 h-2 rounded-full mr-3"
                                  style={{ backgroundColor: colors.secondary }}
                                ></div>
                                {highlight}
                              </div>
                            ))}
                          </div>

                          <a
                            href={charity.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center px-8 py-4 rounded-full text-white text-lg transition-all hover:scale-105 mt-8"
                            style={{ backgroundColor: colors.secondary }}
                          >
                            Visit {charity.name}
                            <ExternalLink className="w-5 h-5 ml-2" />
                          </a>
                        </div>
                      </div>

                      {/* Image Section */}
                      <div className={index % 2 === 0 ? "" : "md:order-1"}>
                        <div className="relative">
                          <div className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-accent/20 rounded-2xl transform -rotate-2" />
                          <img
                            src={charity.image}
                            alt={`${charity.name} impact`}
                            className="relative rounded-2xl shadow-xl w-full"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Distribution Section */}
          <div className="bg-white py-32">
            <div className="max-w-4xl mx-auto px-8">
              <h3 className="text-3xl font-semibold text-slate-800 mb-8 text-center">
                {distribution?.title || "How Funds Are Distributed"}
              </h3>
              <p className="text-lg text-slate-600 mb-16 text-center">
                {distribution?.description ||
                  "100% of all donations are distributed directly to our partner charities."}
              </p>
              <div className="flex justify-center">
                <a
                  href="https://www.tourde4.com/donate"
                  className="inline-flex items-center px-14 py-6 rounded-full text-black font-semibold text-xl transition-all hover:scale-105"
                  style={{ backgroundColor: colors.accent }}
                >
                  Donate Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SponsorCharityPage;
