import React, { createContext, useContext } from "react";
import { useSiteMode as useOriginalSiteMode } from "../hooks/useSiteMode";

const SiteModeContext = createContext(null);

export const SiteModeProvider = ({ children }) => {
  const siteMode = useOriginalSiteMode();
  return (
    <SiteModeContext.Provider value={siteMode}>
      {children}
    </SiteModeContext.Provider>
  );
};

export const useSiteMode = () => {
  const context = useContext(SiteModeContext);
  if (!context) {
    throw new Error("useSiteMode must be used within a SiteModeProvider");
  }
  return context;
};
