import React, { useState } from "react";
import {
  Mail,
  Users,
  MessageCircle,
  Globe,
  ExternalLink,
  ArrowDown,
} from "lucide-react";
import { motion } from "framer-motion";
import { useContent } from "../hooks/useContent";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useSeoSettings } from "../hooks/useSeoSettings";
import { Helmet } from "react-helmet-async";

// Development logger
const logger = {
  debug: (...args) => {
    if (process.env.NODE_ENV === "development") {
      console.log(...args);
    }
  },
  error: (...args) => {
    if (process.env.NODE_ENV === "development") {
      console.error(...args);
    }
  },
};

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

const Contact = () => {
  const { content, loading, error } = useContent("contact");
  const { seoSettings } = useSeoSettings();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    category: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus("");

    try {
      logger.debug("Submitting form with data:", formData);
      const functions = getFunctions();
      const sendEmail = httpsCallable(functions, "sendContactEmail");

      const result = await sendEmail({ data: formData });
      logger.debug("Email function result:", result);

      // Clear the form
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
        category: "",
      });

      setSubmitStatus("Message sent successfully! We'll get back to you soon.");
    } catch (error) {
      logger.error("Form submission error:", error);
      setSubmitStatus(
        `Failed to send message: ${error.message}. Please try again or contact us directly via email.`
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading content</div>;

  return (
    <>
      <Helmet>
        <title>
          {seoSettings?.pages?.contact?.title ||
            "Contact Tour De 4 - Get in Touch"}
        </title>
        <meta
          name="description"
          content={
            seoSettings?.pages?.contact?.description ||
            "Contact the Tour De 4 team for inquiries about participation, support, or media coverage."
          }
        />
        <meta name="theme-color" content={seoSettings?.metaTags?.themeColor} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoSettings?.openGraph?.url} />
        <meta
          property="og:title"
          content={
            seoSettings?.pages?.contact?.title || seoSettings?.openGraph?.title
          }
        />
        <meta
          property="og:description"
          content={
            seoSettings?.pages?.contact?.description ||
            seoSettings?.openGraph?.description
          }
        />
        <meta
          property="og:image"
          content={
            seoSettings?.pages?.contact?.image || seoSettings?.openGraph?.image
          }
        />

        {/* Twitter */}
        <meta property="twitter:card" content={seoSettings?.twitter?.card} />
        <meta property="twitter:url" content={seoSettings?.openGraph?.url} />
        <meta
          property="twitter:title"
          content={
            seoSettings?.pages?.contact?.title || seoSettings?.twitter?.title
          }
        />
        <meta
          property="twitter:description"
          content={
            seoSettings?.pages?.contact?.description ||
            seoSettings?.twitter?.description
          }
        />
        <meta
          property="twitter:image"
          content={
            seoSettings?.pages?.contact?.image || seoSettings?.twitter?.image
          }
        />
      </Helmet>

      <div className="min-h-screen">
        {/* Hero Section */}
        <motion.div
          className="h-screen flex items-center justify-center relative overflow-hidden"
          style={{
            background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
          }}
        >
          {/* Decorative Background Element */}
          <motion.div
            className="absolute top-20 left-20 w-96 h-96 rounded-full opacity-10"
            style={{
              background: colors.secondary,
              filter: "blur(60px)",
            }}
            animate={{
              scale: [1, 1.2, 1],
              rotate: [0, 90, 0],
            }}
            transition={{
              duration: 20,
              ease: "linear",
              repeat: Infinity,
            }}
          />

          <div className="max-w-7xl mx-auto px-8 text-center relative z-10">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.4, delay: 0.2 }}
              className="space-y-8"
            >
              <h1 className="text-7xl font-bold text-white mb-8">
                {content?.hero?.title || "Get in Touch"}
              </h1>
              <p className="text-2xl text-white/70 max-w-3xl mx-auto">
                {content?.hero?.description ||
                  "Join us in making a difference. Whether you're interested in participating, supporting, or learning more about Tour De 4, we're here to help."}
              </p>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.8 }}
              className="mt-12"
            >
              <ArrowDown className="w-8 h-8 text-white/50 mx-auto animate-bounce" />
            </motion.div>
          </div>
        </motion.div>

        {/* Contact Categories Section */}
        <div className="bg-white py-32">
          <div className="max-w-7xl mx-auto px-8">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              className="grid md:grid-cols-3 gap-16"
            >
              {(content?.contactCategories || []).map((item, i) => (
                <motion.div
                  key={i}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: i * 0.2 }}
                >
                  <div className="relative">
                    <div className="absolute inset-0 bg-gradient-to-r from-secondary/20 to-accent/20 rounded-2xl transform -rotate-2" />
                    <div className="relative bg-white rounded-2xl p-12 shadow-lg">
                      {i === 0 && (
                        <Users
                          className="w-12 h-12 mb-6"
                          style={{ color: colors.secondary }}
                        />
                      )}
                      {i === 1 && (
                        <MessageCircle
                          className="w-12 h-12 mb-6"
                          style={{ color: colors.secondary }}
                        />
                      )}
                      {i === 2 && (
                        <Globe
                          className="w-12 h-12 mb-6"
                          style={{ color: colors.secondary }}
                        />
                      )}
                      <h3 className="text-2xl font-semibold mb-4">
                        {item.title}
                      </h3>
                      <p className="text-slate-600 mb-6 text-lg">
                        {item.description}
                      </p>
                      <a
                        href={`mailto:${item.contact}`}
                        className="inline-flex items-center text-lg hover:opacity-80 transition-opacity"
                        style={{ color: colors.secondary }}
                      >
                        {item.contact}
                        <ExternalLink className="w-5 h-5 ml-2" />
                      </a>
                    </div>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          </div>
        </div>

        {/* Contact Form Section */}
        <div
          style={{
            background: `linear-gradient(to bottom right, ${colors.backgroundDark}, ${colors.backgroundLight})`,
          }}
        >
          <div className="max-w-7xl mx-auto px-8 py-32">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
              className="bg-white/10 backdrop-blur-sm rounded-2xl p-12 md:p-16"
            >
              <div className="max-w-3xl mx-auto">
                <h2 className="text-4xl font-bold text-white mb-12 text-center">
                  {content?.form?.title || "Send Us a Message"}
                </h2>
                <form onSubmit={handleSubmit} className="space-y-8">
                  <div className="grid md:grid-cols-2 gap-8">
                    <div>
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full bg-white/10 rounded-full p-4 border-none text-white placeholder:text-white/50 focus:ring-2 focus:ring-white/20"
                        required
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        name="email"
                        placeholder="Your Email"
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full bg-white/10 rounded-full p-4 border-none text-white placeholder:text-white/50 focus:ring-2 focus:ring-white/20"
                        required
                      />
                    </div>
                  </div>

                  <select
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    className="w-full bg-white/10 rounded-full p-4 border-none text-white focus:ring-2 focus:ring-white/20 appearance-none"
                    style={{
                      colorScheme: "dark",
                      WebkitAppearance: "none",
                      backgroundImage:
                        "url(\"data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e\")",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right 1rem center",
                      backgroundSize: "1.5em",
                      paddingRight: "3rem",
                    }}
                  >
                    <option value="" disabled>
                      Select a Category
                    </option>
                    {(
                      content?.form?.categories || [
                        "General Inquiry",
                        "Participation",
                        "Sponsorship",
                        "Media & Press",
                        "Other",
                      ]
                    ).map((category, index) => (
                      <option
                        key={index}
                        value={category.toLowerCase()}
                        className="bg-gray-800 text-white"
                      >
                        {category}
                      </option>
                    ))}
                  </select>

                  <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleChange}
                    className="w-full bg-white/10 rounded-full p-4 border-none text-white placeholder:text-white/50 focus:ring-2 focus:ring-white/20"
                    required
                  />

                  <textarea
                    name="message"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChange}
                    rows={6}
                    className="w-full bg-white/10 rounded-3xl p-6 border-none text-white placeholder:text-white/50 focus:ring-2 focus:ring-white/20"
                    required
                  />

                  <motion.button
                    type="submit"
                    className="w-full rounded-full p-4 flex items-center justify-center space-x-2 text-lg font-semibold transition-all hover:opacity-90"
                    style={{
                      backgroundColor: colors.accent,
                      color: colors.backgroundDark,
                    }}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    disabled={isSubmitting}
                  >
                    <span>{isSubmitting ? "Sending..." : "Send Message"}</span>
                    {isSubmitting ? (
                      <div className="animate-spin rounded-full h-5 w-5 border-2 border-current border-t-transparent" />
                    ) : (
                      <Mail className="w-5 h-5" />
                    )}
                  </motion.button>

                  {submitStatus && (
                    <motion.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className={`text-center ${
                        submitStatus.includes("success")
                          ? "text-green-400"
                          : "text-red-400"
                      }`}
                    >
                      {submitStatus}
                    </motion.p>
                  )}
                </form>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
