import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Navigate, useLocation } from "react-router-dom";
import {
  getRouteProtectionSettings,
  isRouteAccessible,
} from "../firebase/routeProtections";
import { useAuth } from "../hooks/useAuth";

import {
  getSettingsFromCache,
  initializeCache,
} from "../services/contentCache";

const ProtectedRoute = ({ children, routeName = "mainLandingPage" }) => {
  const { user, loading: authLoading, login } = useAuth();
  const [settings, setSettings] = useState(getSettingsFromCache());
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // Wrap useLocation in try-catch to handle potential context issues
  let pathname = "/";
  try {
    const location = useLocation();
    pathname = location.pathname;
  } catch (err) {
    console.error("Router context not available:", err);
  }

  // Initialize cache if needed
  useEffect(() => {
    if (!settings) {
      initializeCache().then(() => {
        setSettings(getSettingsFromCache());
      });
    }
  }, []);

  // Fetch route protection settings
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const fetchedSettings = await getRouteProtectionSettings();
        setSettings(fetchedSettings);
      } catch (error) {
        console.error("Error fetching route settings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  // Handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");

    try {
      const success = await login(email, password);
      if (!success) {
        setError("Invalid credentials");
      }
    } catch (err) {
      setError(err.message || "An error occurred during login");
    }
  };

  // Return children immediately while checking permissions
  if (loading || authLoading) {
    return children;
  }

  if (!settings) {
    return children;
  }

  // Check if the route is accessible
  const isAccessible = isRouteAccessible(settings, routeName);

  // Check admin portal first - always requires authentication
  if (routeName === "adminPortal") {
    if (!user) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="min-h-screen w-full flex items-center justify-center"
          style={{
            background: `linear-gradient(to bottom right, #0f172a, #1e293b)`,
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="w-full max-w-md p-6"
          >
            <form
              onSubmit={handleLogin}
              className="bg-white/10 backdrop-blur-sm rounded-lg p-8 shadow-xl"
            >
              <h2 className="text-xl text-white mb-8 font-semibold">
                Admin Portal - Login Required
              </h2>
              {error && (
                <div className="mb-4 text-red-400 text-sm text-center">
                  {error}
                </div>
              )}
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="w-full px-4 py-2 rounded-md mb-4 bg-white/5 border border-white/10 text-white"
                required
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="w-full px-4 py-2 rounded-md mb-4 bg-white/5 border border-white/10 text-white"
                required
              />
              <motion.button
                type="submit"
                className="w-full px-4 py-2 bg-[#2494a2] text-white rounded-md"
                whileHover={{ opacity: 0.9 }}
                whileTap={{ scale: 0.98 }}
              >
                Login
              </motion.button>
            </form>
          </motion.div>
        </motion.div>
      );
    }
    return children;
  }

  // Handle route protection based on site mode
  if (settings.siteMode === "development") {
    // In dev mode, force /dev/ routes and require authentication
    if (!pathname.startsWith("/dev/") && pathname !== "/") {
      const devPath = `/dev${pathname}`;
      return <Navigate to={devPath} replace />;
    }

    // Require authentication for all /dev/ routes
    if (pathname.startsWith("/dev/") && !user) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="min-h-screen w-full flex items-center justify-center"
          style={{
            background: `linear-gradient(to bottom right, #0f172a, #1e293b)`,
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="w-full max-w-md p-6"
          >
            <form
              onSubmit={handleLogin}
              className="bg-white/10 backdrop-blur-sm rounded-lg p-8 shadow-xl"
            >
              <h2 className="text-xl text-white mb-8 font-semibold">
                Development Mode - Login Required
              </h2>
              {error && (
                <div className="mb-4 text-red-400 text-sm text-center">
                  {error}
                </div>
              )}
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className="w-full px-4 py-2 rounded-md mb-4 bg-white/5 border border-white/10 text-white"
                required
              />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                className="w-full px-4 py-2 rounded-md mb-4 bg-white/5 border border-white/10 text-white"
                required
              />
              <motion.button
                type="submit"
                className="w-full px-4 py-2 bg-[#2494a2] text-white rounded-md"
                whileHover={{ opacity: 0.9 }}
                whileTap={{ scale: 0.98 }}
              >
                Login
              </motion.button>
            </form>
          </motion.div>
        </motion.div>
      );
    }
  } else {
    // In live mode, redirect /dev/* routes to root level
    if (pathname.startsWith("/dev/")) {
      const newPath = pathname.replace("/dev/", "/");
      return <Navigate to={newPath === "/home" ? "/" : newPath} replace />;
    }
  }

  // If route is not accessible (unpublished), show 404 or redirect
  if (!isAccessible) {
    return (
      <div className="min-h-screen w-full flex items-center justify-center bg-slate-800 text-white p-8">
        <div className="text-center">
          <h1 className="text-4xl mb-4">Page Not Available</h1>
          <p className="mb-4">This page is currently not accessible.</p>
          <a href="/" className="text-blue-400 hover:underline">
            Go Home
          </a>
        </div>
      </div>
    );
  }

  return children;
};

export default ProtectedRoute;
