import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  initAnalytics,
  setAnalyticsEnabled as firebaseSetAnalyticsEnabled,
} from "../firebase";
import { app } from "../firebase";
import { Link } from "react-router-dom";

const COOKIE_CONSENT_KEY = "cookieConsent";
const ANALYTICS_CONSENT_KEY = "analyticsConsent";

const useCookieConsent = () => {
  const [hasConsent, setHasConsent] = useState(() => {
    return localStorage.getItem(COOKIE_CONSENT_KEY) === "true";
  });

  const [analyticsEnabled, setAnalyticsEnabled] = useState(() => {
    return localStorage.getItem(ANALYTICS_CONSENT_KEY) === "true";
  });

  const [analyticsInstance, setAnalyticsInstance] = useState(null);

  useEffect(() => {
    // Initialize analytics if consent was previously given
    if (localStorage.getItem(ANALYTICS_CONSENT_KEY) === "true") {
      (async () => {
        try {
          const analytics = await initAnalytics();
          setAnalyticsInstance(analytics);
          await firebaseSetAnalyticsEnabled(true);
        } catch (error) {
          console.error("Error initializing analytics:", error);
        }
      })();
    }
  }, []);

  const setConsent = (value) => {
    setHasConsent(value);
    localStorage.setItem(COOKIE_CONSENT_KEY, value);
  };

  const setAnalytics = async (value) => {
    try {
      let analytics = analyticsInstance;
      if (value && !analytics) {
        analytics = await initAnalytics();
        setAnalyticsInstance(analytics);
      }
      if (analytics) {
        await firebaseSetAnalyticsEnabled(value);
        setAnalyticsEnabled(value);
        localStorage.setItem(ANALYTICS_CONSENT_KEY, value);
      }
    } catch (error) {
      console.error("Error setting analytics:", error);
    }
  };

  return { hasConsent, setConsent, analyticsEnabled, setAnalytics };
};

const CookieConsentBanner = () => {
  const [showSettings, setShowSettings] = useState(false);
  const { hasConsent, setConsent, analyticsEnabled, setAnalytics } =
    useCookieConsent();

  const colors = {
    primaryDark: "#304b78",
    primaryLight: "#435e95",
    secondary: "#2494a2",
    accent: "#f3d678",
    backgroundDark: "#0f172a",
    backgroundLight: "#1e293b",
  };

  const handleAccept = () => {
    setConsent(true);
    setAnalytics(true);
  };

  const handleDecline = () => {
    setConsent(true);
    setAnalytics(false);
  };

  const toggleAnalytics = () => {
    setAnalytics(!analyticsEnabled);
  };

  if (hasConsent) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-slate-900/95 backdrop-blur-sm border-t border-slate-800 p-4 z-50">
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="flex-1">
          <p className="text-sm text-white">
            We use cookies to enhance your browsing experience, serve
            personalized content, and analyze our traffic. Read our{" "}
            <a
              href="/cookies"
              className="font-medium text-white underline hover:text-accent focus:outline-none focus:ring-2 focus:ring-white/50 rounded-sm"
            >
              Cookie Policy
            </a>
          </p>
        </div>
        <div className="flex gap-4">
          <button
            onClick={handleAccept}
            className="px-6 py-2 rounded-lg font-medium bg-white text-slate-900 hover:bg-white/90 focus:outline-none focus:ring-2 focus:ring-white/50 transition-colors"
          >
            Accept
          </button>
          <button
            onClick={handleDecline}
            className="px-6 py-2 rounded-lg font-medium text-white border-2 border-white hover:bg-white/10 focus:outline-none focus:ring-2 focus:ring-white/50 transition-colors"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsentBanner;
