// Footer.jsx
import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Facebook, Instagram, Mail, Phone } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { useSiteMode } from "../hooks/useSiteMode";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
  limit,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { trackEmailSignup } from "../utils/analytics";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

// Email sanitization function
const sanitizeEmail = (email) => {
  if (!email) return "";

  // Remove any potentially dangerous characters
  let sanitized = email
    .replace(/<[^>]*>/g, "") // Remove HTML tags
    .replace(/javascript:/gi, "") // Remove javascript: protocol
    .replace(/onerror/gi, "") // Remove onerror handlers
    .replace(/[^\w.@+-]/g, "") // Only allow safe characters
    .toLowerCase()
    .trim();
  return sanitized;
};

// Enhanced email validation
const isValidEmail = (email) => {
  if (!email) return false;

  // Basic format check
  const basicFormat =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  if (!basicFormat.test(email)) return false;

  // Additional checks
  const parts = email.split("@");
  if (parts.length !== 2) return false;

  const [local, domain] = parts;

  // Check lengths
  if (local.length > 64) return false;
  if (domain.length > 255) return false;

  // Must have at least one dot in domain
  if (!domain.includes(".")) return false;

  // Domain must end with at least 2 characters
  const topLevel = domain.split(".").pop();
  if (!topLevel || topLevel.length < 2) return false;
  return true;
};

// Rate limiting configuration
const RATE_LIMIT = {
  maxAttempts: 5,
  windowMs: 15 * 60 * 1000, // 15 minutes
};

const XIcon = ({ className }) => (
  <svg
    viewBox="0 0 24 24"
    className={className}
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
  </svg>
);

const Footer = () => {
  const navigate = useNavigate();
  const { getPath } = useSiteMode();
  const currentYear = new Date().getFullYear();
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: "", message: "" });
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [footerContent, setFooterContent] = useState({
    about: {
      text: "A ground-breaking charity cycling event challenging perceptions and raising vital awareness for Stage 4 cancer.",
    },
    quickLinks: { links: [] },
    contact: { email: "", phone: "" },
    social: { links: { facebook: "#", x: "#", instagram: "#" } },
  });

  // Navigation mapping
  const navMapping = {
    About: "/about",
    "Get Involved": "/get-involved",
    Support: "/support",
    Resources: "/resources",
    "News & Media": "/news",
    Contact: "/contact",
  };

  const handleNavigation = (link) => {
    const path = navMapping[link];
    if (path) {
      navigate(getPath(path));
    }
  };

  // Add handleFooterNavigation function
  const handleFooterNavigation = (path) => {
    navigate(getPath(path));
    window.scrollTo(0, 0);
  };

  // Subscribe to footer content changes
  React.useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "content", "footer"), (doc) => {
      if (doc.exists()) {
        setFooterContent(doc.data());
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Check rate limit function
  const checkRateLimit = () => {
    try {
      const now = Date.now();
      const attempts = JSON.parse(
        localStorage.getItem("registration_attempts") || "[]"
      );
      const validAttempts = attempts.filter(
        (attempt) => now - attempt.timestamp < RATE_LIMIT.windowMs
      );
      localStorage.setItem(
        "registration_attempts",
        JSON.stringify(validAttempts)
      );
      return validAttempts.length >= RATE_LIMIT.maxAttempts;
    } catch (error) {
      console.error("Rate limit check failed:", error);
      return false;
    }
  };

  // Record attempt function
  const recordAttempt = () => {
    try {
      const attempts = JSON.parse(
        localStorage.getItem("registration_attempts") || "[]"
      );
      attempts.push({ timestamp: Date.now() });
      localStorage.setItem("registration_attempts", JSON.stringify(attempts));
    } catch (error) {
      console.error("Failed to record attempt:", error);
    }
  };

  // Handle email change with validation
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (emailError) setEmailError("");
    if (submitStatus.message) setSubmitStatus({ type: "", message: "" });

    // Real-time validation feedback
    if (value && !isValidEmail(sanitizeEmail(value))) {
      setEmailError("Please enter a valid email address");
    }
  };

  // Enhanced submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailError("");
    setSubmitStatus({ type: "", message: "" });

    // Check rate limit first
    if (checkRateLimit()) {
      setSubmitStatus({
        type: "error",
        message: "Too many attempts. Please try again later.",
      });
      return;
    }

    const sanitizedEmail = sanitizeEmail(email);
    if (!isValidEmail(sanitizedEmail)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    setIsSubmitting(true);

    try {
      // Record the attempt before proceeding
      recordAttempt();

      const registrationData = {
        email: sanitizedEmail,
        source: "subscribe",
        timestamp: serverTimestamp(),
        registrationAttempt: {
          timestamp: Date.now(),
          clientTime: new Date().toISOString(),
          userAgent: navigator.userAgent,
        },
      };

      // Check for existing registration
      const emailsRef = collection(db, "registrations");
      const q = query(
        emailsRef,
        where("email", "==", sanitizedEmail),
        limit(1)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setSubmitStatus({
          type: "info",
          message: "This email is already registered!",
        });
        return;
      }

      // Add the document with enhanced data
      await addDoc(collection(db, "registrations"), registrationData);

      // Track successful signup
      trackEmailSignup(sanitizedEmail);

      setSubmitStatus({
        type: "success",
        message: "Thank you for subscribing!",
      });
      setEmail("");

      setTimeout(() => {
        setSubmitStatus({ type: "", message: "" });
      }, 5000);
    } catch (error) {
      console.error("Registration failed:", error);
      setSubmitStatus({
        type: "error",
        message: "Registration failed. Please try again later.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <footer className="bg-[#0f172a] bg-opacity-95 backdrop-blur-md text-white/80">
      <div className="max-w-7xl mx-auto px-4 pt-16 pb-8">
        {/* Pre-render a fixed height container */}
        <div
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 mb-12"
          style={{ minHeight: "300px" }} // Ensure consistent height during loading
        >
          {/* About Section */}
          <div className="space-y-4 min-h-[200px] flex flex-col">
            <Link
              to={getPath("/")}
              className="block h-12 w-[48px] flex-shrink-0"
              style={{ opacity: isLoading ? 0.5 : 1 }}
            >
              <motion.img
                whileHover={{ scale: 1.05 }}
                src="/TD4-logo.svg"
                alt="Tour De Four Logo"
                className="h-12 w-[48px]"
                width={48}
                height={48}
                loading="eager"
                decoding="async"
                style={{
                  transform: "translateZ(0)",
                  willChange: "transform",
                  contentVisibility: "auto",
                }}
              />
            </Link>
            <div className="flex-grow">
              <p className="text-sm min-h-[72px]">
                {isLoading ? (
                  <span className="block w-full h-full bg-white/10 animate-pulse rounded" />
                ) : (
                  footerContent.about.text
                )}
              </p>
            </div>
            <div className="flex space-x-4 h-5">
              <motion.a
                href={footerContent.social.links.facebook}
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                className="hover:text-secondary"
                aria-label="Visit our Facebook page"
              >
                <Facebook className="w-5 h-5" aria-hidden="true" />
                <span className="sr-only">Visit our Facebook page</span>
              </motion.a>
              <motion.a
                href={footerContent.social.links.x}
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                className="hover:text-secondary"
                aria-label="Visit our X (formerly Twitter) page"
              >
                <XIcon className="w-5 h-5" aria-hidden="true" />
                <span className="sr-only">
                  Visit our X (formerly Twitter) page
                </span>
              </motion.a>
              <motion.a
                href={footerContent.social.links.instagram}
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                className="hover:text-secondary"
                aria-label="Visit our Instagram page"
              >
                <Instagram className="w-5 h-5" aria-hidden="true" />
                <span className="sr-only">Visit our Instagram page</span>
              </motion.a>
            </div>
          </div>

          {/* Quick Links */}
          <div className="min-h-[200px] flex flex-col">
            <h3
              className="text-white font-semibold mb-4"
              style={{ opacity: isLoading ? 0.5 : 1 }}
            >
              Quick Links
            </h3>
            <div className="flex-grow">
              <ul className="space-y-2 min-h-[160px]">
                {isLoading
                  ? Array(6)
                      .fill(0)
                      .map((_, i) => (
                        <li key={i} className="h-6">
                          <span className="block w-24 h-4 bg-white/10 animate-pulse rounded" />
                        </li>
                      ))
                  : (footerContent.quickLinks.links.length > 0
                      ? footerContent.quickLinks.links
                      : [
                          "About",
                          "Get Involved",
                          "Support",
                          "Resources",
                          "News & Media",
                          "Contact",
                        ]
                    ).map((item) => (
                      <li key={item}>
                        <button
                          onClick={() => handleNavigation(item)}
                          className="hover:text-white transition-colors"
                        >
                          {item}
                        </button>
                      </li>
                    ))}
              </ul>
            </div>
          </div>

          {/* Contact Info */}
          <div className="min-h-[200px] flex flex-col">
            <h3
              className="text-white font-semibold mb-4"
              style={{ opacity: isLoading ? 0.5 : 1 }}
            >
              Contact Us
            </h3>
            <div className="flex-grow">
              <ul className="space-y-4 min-h-[160px]">
                {isLoading ? (
                  Array(3)
                    .fill(0)
                    .map((_, i) => (
                      <li key={i} className="flex items-center space-x-2">
                        <Mail className="w-4 h-4 flex-shrink-0 text-white/20" />
                        <span className="block w-32 h-4 bg-white/10 animate-pulse rounded" />
                      </li>
                    ))
                ) : (
                  <>
                    <li className="flex items-center space-x-2">
                      <Mail className="w-4 h-4 flex-shrink-0" />
                      <a
                        href={`mailto:${
                          footerContent.contact.email || "info@tourde4.com"
                        }`}
                        className="hover:text-white"
                      >
                        {footerContent.contact.email || "info@tourde4.com"}
                      </a>
                    </li>
                    <li className="flex items-center space-x-2">
                      <Mail className="w-4 h-4 flex-shrink-0" />
                      <a
                        href="mailto:media@tourde4.com"
                        className="hover:text-white"
                      >
                        media@tourde4.com
                      </a>
                    </li>
                    <li className="flex items-center space-x-2">
                      <Mail className="w-4 h-4 flex-shrink-0" />
                      <a
                        href="mailto:sponsorship@tourde4.com"
                        className="hover:text-white"
                      >
                        sponsorship@tourde4.com
                      </a>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>

          {/* Newsletter */}
          <div className="min-h-[200px] flex flex-col">
            <h3
              className="text-white font-semibold mb-4"
              style={{ opacity: isLoading ? 0.5 : 1 }}
            >
              Stay Updated
            </h3>
            <div className="flex-grow">
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="relative">
                  <input
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Your email"
                    className="w-full px-4 py-2 bg-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-secondary"
                    disabled={isLoading}
                  />
                  <div className="h-8 mt-2">
                    <AnimatePresence>
                      {(emailError || submitStatus.message) && (
                        <motion.div
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0 }}
                          className={`text-sm ${
                            emailError
                              ? "text-red-400"
                              : submitStatus.type === "success"
                              ? "text-green-400"
                              : submitStatus.type === "info"
                              ? "text-blue-400"
                              : "text-red-400"
                          }`}
                        >
                          {emailError || submitStatus.message}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    type="submit"
                    disabled={isSubmitting || isLoading}
                    className="mt-2 w-full px-4 py-2 rounded-lg font-medium disabled:opacity-100 text-white hover:bg-opacity-90"
                    style={{ background: colors.secondary }}
                  >
                    {isLoading
                      ? "Loading..."
                      : isSubmitting
                      ? "Subscribing..."
                      : "Subscribe"}
                  </motion.button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Bottom Bar - Fixed height */}
        <div
          className="pt-8 border-t border-white/10 text-sm"
          style={{ minHeight: "80px" }}
        >
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0 min-h-[48px]">
            <p style={{ opacity: isLoading ? 0.5 : 1 }}>
              &copy; {currentYear} Tour De 4. All rights reserved.
            </p>
            <div className="flex space-x-6">
              <button
                onClick={() => handleFooterNavigation("/privacy")}
                className="hover:text-white"
              >
                Privacy Policy
              </button>
              <button
                onClick={() => handleFooterNavigation("/terms")}
                className="hover:text-white"
              >
                Terms of Service
              </button>
              <button
                onClick={() => handleFooterNavigation("/cookies")}
                className="hover:text-white"
              >
                Cookie Policy
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
