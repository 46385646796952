import React from "react";
import { motion } from "framer-motion";

const PageTransition = ({ children }) => {
  return (
    <>
      <motion.div
        className="fixed inset-0 z-[9999] pointer-events-none bg-[#0f172a]"
        initial={{ opacity: 0.7 }}
        animate={{ opacity: 0 }}
        exit={{ opacity: 0.7 }}
        transition={{
          type: "tween",
          ease: "easeInOut",
          duration: 0.2,
          delay: 0.1,
        }}
      />
      <motion.div
        className="relative z-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          type: "tween",
          ease: "easeInOut",
          duration: 0.2,
        }}
      >
        {children}
      </motion.div>
    </>
  );
};

export default PageTransition;
