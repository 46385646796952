import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "lucide-react";
import { createPortal } from "react-dom";
import { db } from "../firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
  limit,
} from "firebase/firestore";
import { trackEmailSignup } from "../utils/analytics";
import { useContent } from "../hooks/useContent";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

// Email sanitization function
const sanitizeEmail = (email) => {
  if (!email) return "";

  let sanitized = email
    .replace(/<[^>]*>/g, "")
    .replace(/javascript:/gi, "")
    .replace(/onerror/gi, "")
    .replace(/[^\w.@+-]/g, "")
    .toLowerCase()
    .trim();
  return sanitized;
};

// Enhanced email validation
const isValidEmail = (email) => {
  if (!email) return false;

  const basicFormat =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  if (!basicFormat.test(email)) return false;

  const parts = email.split("@");
  if (parts.length !== 2) return false;

  const [local, domain] = parts;

  if (local.length > 64) return false;
  if (domain.length > 255) return false;

  if (!domain.includes(".")) return false;

  const topLevel = domain.split(".").pop();
  if (!topLevel || topLevel.length < 2) return false;
  return true;
};

// Rate limiting configuration
const RATE_LIMIT = {
  maxAttempts: 5,
  windowMs: 15 * 60 * 1000,
};

const RegisterInterestModal = ({ isOpen, onClose, source = "homepage" }) => {
  const { content: siteConfig } = useContent("siteConfig");
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: "", message: "" });
  const [emailError, setEmailError] = useState("");

  // Remove the scroll effect
  useEffect(() => {
    if (siteConfig?.disableRegistrationModal && isOpen) {
      onClose();
    }
  }, [siteConfig?.disableRegistrationModal, isOpen, onClose]);

  // If registration modal is disabled, don't render anything
  if (siteConfig?.disableRegistrationModal) {
    return null;
  }

  // Check rate limit function
  const checkRateLimit = () => {
    try {
      const now = Date.now();
      const attempts = JSON.parse(
        localStorage.getItem("registration_attempts") || "[]"
      );
      const validAttempts = attempts.filter(
        (attempt) => now - attempt.timestamp < RATE_LIMIT.windowMs
      );
      localStorage.setItem(
        "registration_attempts",
        JSON.stringify(validAttempts)
      );
      return validAttempts.length >= RATE_LIMIT.maxAttempts;
    } catch (error) {
      console.error("Rate limit check failed:", error);
      return false;
    }
  };

  // Record attempt function
  const recordAttempt = () => {
    try {
      const attempts = JSON.parse(
        localStorage.getItem("registration_attempts") || "[]"
      );
      attempts.push({ timestamp: Date.now() });
      localStorage.setItem("registration_attempts", JSON.stringify(attempts));
    } catch (error) {
      console.error("Failed to record attempt:", error);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (emailError) setEmailError("");
    if (submitStatus.message) setSubmitStatus({ type: "", message: "" });

    if (value && !isValidEmail(sanitizeEmail(value))) {
      setEmailError("Please enter a valid email address");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailError("");
    setSubmitStatus({ type: "", message: "" });

    if (checkRateLimit()) {
      setSubmitStatus({
        type: "error",
        message: "Too many attempts. Please try again later.",
      });
      return;
    }

    const sanitizedEmail = sanitizeEmail(email);
    if (!isValidEmail(sanitizedEmail)) {
      setEmailError("Please enter a valid email address");
      return;
    }

    setIsSubmitting(true);

    try {
      recordAttempt();

      const registrationData = {
        email: sanitizedEmail,
        source: source,
        timestamp: serverTimestamp(),
        registrationAttempt: {
          timestamp: Date.now(),
          clientTime: new Date().toISOString(),
          userAgent: navigator.userAgent,
        },
      };

      const emailsRef = collection(db, "registrations");
      const q = query(
        emailsRef,
        where("email", "==", sanitizedEmail),
        limit(1)
      );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setSubmitStatus({
          type: "info",
          message: "This email is already registered!",
        });
        return;
      }

      await addDoc(collection(db, "registrations"), registrationData);
      trackEmailSignup(sanitizedEmail);

      setSubmitStatus({
        type: "success",
        message: "Thank you for registering your interest!",
      });
      setEmail("");

      // Close modal after successful registration
      setTimeout(() => {
        onClose();
        setSubmitStatus({ type: "", message: "" });
      }, 2000);
    } catch (error) {
      console.error("Registration failed:", error);
      setSubmitStatus({
        type: "error",
        message: "Registration failed. Please try again later.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-[100] flex items-center justify-center bg-black/50 backdrop-blur-sm"
          onClick={(e) => {
            if (e.target === e.currentTarget) onClose();
          }}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="relative w-full max-w-md mx-4 p-6 bg-[#1e293b] rounded-xl shadow-xl"
          >
            {/* Close button */}
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-white/60 hover:text-white"
            >
              <X className="w-5 h-5" />
            </button>

            {/* Modal content */}
            <div className="text-center mb-6">
              <h2 className="text-2xl font-bold text-white mb-2">
                Register Your Interest
              </h2>
              <p className="text-white/60 mb-4">
                Stay updated with the latest news and developments.
              </p>
              <div className="bg-secondary/20 p-4 rounded-lg mb-6">
                <p className="text-white font-medium">
                  Registrations will open on 28th February, 2025
                </p>
                <p className="text-white/80 text-sm mt-2">
                  Enter your email below to be notified when registrations open
                </p>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="relative">
                <input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Your email"
                  className="w-full px-4 py-3 bg-white/10 rounded-lg text-white placeholder-white/40 focus:outline-none focus:ring-2 focus:ring-secondary"
                />
                <AnimatePresence>
                  {(emailError || submitStatus.message) && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0 }}
                      className={`text-sm mt-2 ${
                        emailError
                          ? "text-red-400"
                          : submitStatus.type === "success"
                          ? "text-green-400"
                          : submitStatus.type === "info"
                          ? "text-blue-400"
                          : "text-red-400"
                      }`}
                    >
                      {emailError || submitStatus.message}
                    </motion.div>
                  )}
                </AnimatePresence>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="submit"
                  disabled={isSubmitting}
                  className="mt-4 w-full px-6 py-3 rounded-lg font-medium text-white disabled:opacity-70 transition-all"
                  style={{ background: colors.secondary }}
                >
                  {isSubmitting ? "Registering..." : "Register Interest"}
                </motion.button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default RegisterInterestModal;
