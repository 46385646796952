import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useContent } from "../hooks/useContent";
import { Helmet } from "react-helmet-async";
import { ArrowLeft, Share2, Check, ExternalLink } from "lucide-react";
import { useSeoSettings } from "../hooks/useSeoSettings";
import { getCdnUrl } from "../utils/cdnUtils";

// Helper function to ensure external links are properly formatted
const formatExternalLink = (link) => {
  if (!link) return "";
  if (link.startsWith("http://") || link.startsWith("https://")) {
    return link;
  }
  return `https://${link}`;
};

const MediaArticlePage = () => {
  const { mediaId } = useParams();
  const { content, loading } = useContent("newsAndMedia");
  const { seoSettings } = useSeoSettings();
  const [copied, setCopied] = useState(false);

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy URL:", err);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-slate-800 flex items-center justify-center">
        <div className="text-white">Loading...</div>
      </div>
    );
  }

  const article = content?.mediaItems?.find((item) => item.id === mediaId);

  if (!article) {
    return (
      <div className="min-h-screen bg-slate-800 flex flex-col items-center justify-center text-white">
        <h1 className="text-2xl mb-4">Media article not found</h1>
        <Link
          to="/dev/news"
          className="text-secondary hover:text-accent transition-colors flex items-center"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to News & Media
        </Link>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        {article.image && (
          <link
            rel="preload"
            as="image"
            href={getCdnUrl(article.image)}
            fetchpriority="high"
          />
        )}
        <title>
          {article.title} |{" "}
          {seoSettings?.pages?.newsAndMedia?.title ||
            "News & Media - Tour De 4"}
        </title>
        <meta name="description" content={article.excerpt || article.title} />
        {/* Open Graph */}
        <meta property="og:title" content={article.title} />
        <meta
          property="og:description"
          content={article.excerpt || article.title}
        />
        {article.image && (
          <meta property="og:image" content={getCdnUrl(article.image)} />
        )}
      </Helmet>

      <div className="min-h-screen bg-white">
        {/* Hero Image */}
        {article.image && (
          <div className="relative h-[60vh] min-h-[500px]">
            <div
              className="absolute inset-0 bg-cover bg-center"
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.7)), url(${getCdnUrl(
                  article.image
                )})`,
              }}
            />
          </div>
        )}

        {/* Navigation and Title Section */}
        <div className="bg-slate-900 pt-28 pb-16">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <div className="flex items-center mb-8">
                <Link
                  to="/dev/news"
                  className="inline-flex items-center text-secondary hover:text-accent transition-colors text-lg"
                >
                  <ArrowLeft className="w-5 h-5 mr-2" />
                  Back to News & Media
                </Link>
              </div>
              <h1 className="text-4xl md:text-6xl font-bold mb-6 text-white leading-tight">
                {article.title}
              </h1>
              <div className="flex items-center space-x-6 text-lg">
                <p className="text-white/70">
                  {new Date(article.date).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </p>
                <p className="text-secondary font-medium">{article.source}</p>
              </div>
            </motion.div>
          </div>
        </div>

        {/* Article Content with Side Image */}
        <motion.article
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-20"
        >
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-16">
            {/* Main Content */}
            <div className="lg:col-span-7">
              <div className="prose prose-lg max-w-none">
                <div
                  className="text-2xl text-slate-600 mb-10 font-medium leading-relaxed prose prose-lg"
                  dangerouslySetInnerHTML={{ __html: article.excerpt }}
                />
                {article.content && (
                  <div
                    className="mt-10 prose-headings:text-slate-800 prose-p:text-slate-600 prose-p:leading-relaxed prose-a:text-secondary hover:prose-a:text-accent prose-strong:text-slate-800 prose-ul:text-slate-600 prose-ol:text-slate-600"
                    dangerouslySetInnerHTML={{ __html: article.content }}
                  />
                )}
              </div>
              <div className="mt-16 border-t pt-8 border-slate-200 flex flex-wrap gap-4">
                <button
                  onClick={handleShare}
                  className="inline-flex items-center px-8 py-4 bg-slate-100 hover:bg-slate-200 rounded-full text-slate-800 transition-colors font-medium"
                >
                  {copied ? (
                    <>
                      <Check className="w-5 h-5 mr-3" />
                      Copied!
                    </>
                  ) : (
                    <>
                      <Share2 className="w-5 h-5 mr-3" />
                      Share Article
                    </>
                  )}
                </button>
                {article.link && (
                  <a
                    href={formatExternalLink(article.link)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center px-8 py-4 bg-secondary/10 hover:bg-secondary/20 rounded-full text-secondary transition-colors font-medium"
                  >
                    <ExternalLink className="w-5 h-5 mr-3" />
                    Read Original Article
                  </a>
                )}
              </div>
            </div>

            {/* Side Image */}
            {article.sourceLogo && (
              <div className="lg:col-span-5">
                <div className="sticky top-10">
                  <div className="min-h-[500px] flex items-center justify-center">
                    <img
                      src={article.sourceLogo}
                      alt={`${article.source} logo`}
                      className="w-full h-auto max-h-[600px] rounded-lg object-contain shadow-xl"
                      onError={(e) => {
                        e.target.style.display = "none";
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </motion.article>
      </div>
    </>
  );
};

export default MediaArticlePage;
