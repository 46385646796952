import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { getCdnUrl } from "../utils/cdnUtils";
import { logger } from "../utils/logger";

const colors = {
  primaryDark: "#304b78",
  primaryLight: "#435e95",
  secondary: "#2494a2",
  accent: "#f3d678",
  backgroundDark: "#0f172a",
  backgroundLight: "#1e293b",
};

const SponsorLogo = ({ sponsor }) => {
  const [svgContent, setSvgContent] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (sponsor.logo?.endsWith(".svg")) {
      fetch(sponsor.logo)
        .then((response) => response.text())
        .then((text) => {
          // Basic validation that it's an SVG
          if (text.includes("<svg")) {
            setSvgContent(text);
          } else {
            setError(true);
          }
        })
        .catch((err) => {
          logger.error("Error loading SVG:", err);
          setError(true);
        });
    }
  }, [sponsor.logo]);

  if (!sponsor.logo) return null;

  if (sponsor.logo.endsWith(".svg")) {
    if (svgContent) {
      return (
        <div
          className="w-full h-full"
          dangerouslySetInnerHTML={{ __html: svgContent }}
          style={{
            filter: "brightness(0) invert(1)",
            opacity: 0.7,
            transition: "opacity 0.2s",
          }}
          onMouseEnter={(e) => (e.currentTarget.style.opacity = 1)}
          onMouseLeave={(e) => (e.currentTarget.style.opacity = 0.7)}
        />
      );
    }
    if (error) {
      // Fallback to img tag if SVG loading fails
      return (
        <img
          src={sponsor.logo}
          alt={`${sponsor.name} logo`}
          className="w-full h-full object-contain opacity-70 group-hover:opacity-100"
          style={{ filter: "brightness(0) invert(1)" }}
          onError={(e) => {
            logger.error("Fallback image load error for:", sponsor.name);
            e.target.style.display = "none";
          }}
        />
      );
    }
    return <div className="w-full h-full animate-pulse bg-gray-300/20" />;
  }

  // For non-SVG images
  return (
    <img
      src={sponsor.logo}
      alt={`${sponsor.name} logo`}
      className="w-full h-full object-contain opacity-70 group-hover:opacity-100"
      onError={(e) => {
        logger.error("Image load error for:", sponsor.name);
        e.target.style.display = "none";
      }}
    />
  );
};

const SponsorsSection = ({ sponsors }) => {
  if (!sponsors?.items?.length) return null;

  return (
    <section className="bg-white py-16 px-4">
      <div className="max-w-6xl mx-auto text-center space-y-12">
        <div>
          <h3 className="text-2xl font-bold text-secondary">
            {sponsors.title || "Our Partners"}
          </h3>
          {sponsors.description && (
            <p className="text-gray-600 mt-2">{sponsors.description}</p>
          )}
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] max-w-[1000px] gap-8 items-center justify-items-center mx-auto">
          {sponsors.items.map((sponsor, index) => {
            // Debug log
            const cdnUrl = getCdnUrl(sponsor.logo);
            logger.info("Original URL:", sponsor.logo);
            logger.info("CDN URL:", cdnUrl);

            return (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="flex items-center justify-center"
              >
                {sponsor.logo && (
                  <img
                    src={cdnUrl}
                    alt={sponsor.name || "Sponsor logo"}
                    className="max-h-20 w-auto object-contain mx-auto"
                    loading={index < 4 ? "eager" : "lazy"}
                    fetchpriority={index < 4 ? "high" : "auto"}
                    width={80}
                    height={80}
                    decoding="async"
                    style={{
                      transform: "translateZ(0)",
                      willChange: "transform",
                      contentVisibility: "auto",
                    }}
                  />
                )}
              </motion.div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default SponsorsSection;
