import { initAnalytics } from "../firebase";
import { logEvent as firebaseLogEvent } from "firebase/analytics";

export async function trackPageView(page, title) {
  const analytics = await initAnalytics();
  if (analytics) {
    firebaseLogEvent(analytics, "page_view", {
      page_path: page,
      page_title: title,
    });
  }
}

export async function trackEvent({ category, action, label }) {
  const analytics = await initAnalytics();
  if (analytics) {
    firebaseLogEvent(analytics, action, {
      event_category: category,
      event_label: label,
    });
  }
}

export async function trackEmailSignup(source) {
  const analytics = await initAnalytics();
  if (analytics) {
    firebaseLogEvent(analytics, "sign_up", {
      method: "email",
      source,
    });
  }
}

export async function trackSocialShare(platform) {
  const analytics = await initAnalytics();
  if (analytics) {
    firebaseLogEvent(analytics, "share", {
      method: platform,
    });
  }
}

export async function sendAnalyticsEvent(eventName, payload) {
  const analytics = await initAnalytics();
  if (analytics) {
    firebaseLogEvent(analytics, eventName, payload);
  }
}
