import { getRouteProtectionSettings } from "../firebase/routeProtections";

// Global cache for all content
const contentCache = {
    settings: null,
    content: {},
    lastUpdate: null
};

// Check if cache is stale (older than 24 hours or doesn't exist)
const isCacheStale = () => {
    if (!contentCache.lastUpdate) return true;
    const now = Date.now();
    const cacheAge = now - contentCache.lastUpdate;
    return cacheAge > 24 * 60 * 60 * 1000; // 24 hours
};

// Initialize cache with content
export const initializeCache = async () => {
    try {
        // Fetch route settings
        const settings = await getRouteProtectionSettings();
        contentCache.settings = settings;

        // Here you would fetch all other content needed for the site
        // For example:
        // const homeContent = await fetchHomeContent();
        // const aboutContent = await fetchAboutContent();
        // etc...

        contentCache.lastUpdate = Date.now();

        // Store in localStorage as backup
        localStorage.setItem('siteContent', JSON.stringify(contentCache));

        return true;
    } catch (error) {
        console.error('Failed to initialize cache:', error);
        return false;
    }
};

// Get content from cache
export const getFromCache = (key) => {
    // Try memory cache first
    if (contentCache.content[key]) {
        return contentCache.content[key];
    }

    // Try localStorage backup
    const storedCache = localStorage.getItem('siteContent');
    if (storedCache) {
        const parsed = JSON.parse(storedCache);
        if (parsed.content[key]) {
            // Restore to memory cache
            contentCache.content[key] = parsed.content[key];
            return parsed.content[key];
        }
    }

    return null;
};

// Get settings from cache
export const getSettingsFromCache = () => {
    if (contentCache.settings) {
        return contentCache.settings;
    }

    // Try localStorage backup
    const storedCache = localStorage.getItem('siteContent');
    if (storedCache) {
        const parsed = JSON.parse(storedCache);
        if (parsed.settings) {
            contentCache.settings = parsed.settings;
            return parsed.settings;
        }
    }

    return null;
};

// Update cache (called after admin panel updates)
export const updateCache = async (newContent) => {
    contentCache.content = {
        ...contentCache.content,
        ...newContent
    };
    contentCache.lastUpdate = Date.now();

    // Update localStorage
    localStorage.setItem('siteContent', JSON.stringify(contentCache));
};

// Force refresh cache
export const refreshCache = async () => {
    contentCache.content = {};
    contentCache.settings = null;
    contentCache.lastUpdate = null;
    localStorage.removeItem('siteContent');
    return initializeCache();
}; 